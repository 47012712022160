
import { filter, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, TemplateRef, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsService } from '../../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { DeviceDailySummariesService, DeviceLocationsService, DeviceLocationSettingsService, CountriesService, BearingsService } from '../../../../core/api/api.services';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MappingService, mapMarkerTypes } from '../../../../core/mapping/mapping.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Map, MapboxGeoJSONFeature, Marker, MarkerOptions, MapMouseEvent, GeoJSONSource, LngLatLike, LngLatBounds, LngLat } from 'mapbox-gl';
import * as GeoJSON from 'geojson';
import { MapComponent } from 'ngx-mapbox-gl';
import { GridOptions, ChartType } from 'ag-grid-community';
import { DevicesViewComponent } from '../../views/devicesview/devicesview.component';

declare const H: any;

export enum DeviceLocationsFormState {
  Initializing = 1,
  List = 2,
  Read = 3,
  New = 4,
  Edit = 5,
  Save = 6,
  Saving = 7,
  Saved = 8,
  Cancel = 9,
  Cancelled = 10,
}
@Component({
  selector: 'app-devicelocations-form',
  templateUrl: './devicelocations-form.component.html',
  styleUrls: ['./devicelocations-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceLocationsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  modalRef: BsModalRef;

  @Input() public device: any = null;

  @Output() onFormEventDevice: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeviceLocationSelected: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() locationModified: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('deviceLocationsTable') dataTable: DatatableComponent;
  @ViewChild('mglMap') mglMap: MapComponent;
  @ViewChild('markerLoc') markerLoc: any; //MarkerComponent, not exported from lib
  @ViewChild(DevicesViewComponent) public devicesView: DevicesViewComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  public chartThemes;
  public rowStyle;
  public gridOptions: GridOptions;
  public isSelected: boolean = false;
  public bulkUpdating: boolean = false;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService,
    public deviceDailySummariesService: DeviceDailySummariesService,
    public deviceLocationsService: DeviceLocationsService,
    public deviceLocationSettingsService: DeviceLocationSettingsService,
    public bearingsService: BearingsService,
    public countriesService: CountriesService,
    public mappingService: MappingService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
  ) {
    this.chartThemes = [
      'ag-pastel',
      'ag-default',
      'ag-material-dark',
      'ag-vivid-dark',
      'ag-solar',
    ];
    this.rowStyle = {
      cursor: 'pointer'
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      rowStyle: this.rowStyle,
      rowSelection: 'multiple',
      cursor: "pointer",
      suppressRowClickSelection: true,
      onCellClicked: (e) => {
        if (e.colDef.field != 'serial') { // cell is from non-select column
          e.node.setSelected(true);
          this.isSelected = true;
        }
      },

      enableCharts: false,
      enableRangeSelection: false
    }

  }

  columnDefs = [
    {
      headerName: "Date", field: "recordDate", sortable: true, suppressRowClickSelection: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (new Date(data.value)).toLocaleDateString()
          : '') : '';
      }
    },
    { headerName: "Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Street Address", field: "deviceLocation.address", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "City", field: "deviceLocation.city", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "State/Prov", field: "deviceLocation.stateProvinceCode", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Country", field: "deviceLocation.country", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Latitude", field: "deviceLocation.latitude", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Longitude", field: "deviceLocation.longitude", sortable: true, resizable: true, flex: 1, suppressMenu: true }
  ]

  datePipe = new DatePipe('en-US');
  private paramSub: any;
  public timeSpanOLD: string[] =
    ["Not Set", "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45",
      "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
      "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45",
      "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
      "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45",
      "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
      "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45",
      "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45", "Midnight"]

  public timeSpans: any[] =
    [
      { display: "Not Set", value: "" },
      { display: "00:00", value: "00:00" },
      { display: "00:15", value: "00:15" },
      { display: "00:30", value: "00:30" },
      { display: "00:45", value: "00:45" },
      { display: "01:00", value: "01:00" },
      { display: "01:15", value: "01:15" },
      { display: "01:30", value: "01:30" },
      { display: "01:45", value: "01:45" },
      { display: "02:00", value: "02:00" },
      { display: "02:15", value: "02:15" },
      { display: "02:30", value: "02:30" },
      { display: "02:45", value: "02:45" },
      { display: "03:00", value: "03:00" },
      { display: "03:15", value: "03:15" },
      { display: "03:30", value: "03:30" },
      { display: "03:45", value: "03:45" },
      { display: "04:00", value: "04:00" },
      { display: "04:15", value: "04:15" },
      { display: "04:30", value: "04:30" },
      { display: "04:45", value: "04:45" },
      { display: "05:00", value: "05:00" },
      { display: "05:15", value: "05:15" },
      { display: "05:30", value: "05:30" },
      { display: "05:45", value: "05:45" },
      { display: "06:00", value: "06:00" },
      { display: "06:15", value: "06:15" },
      { display: "06:30", value: "06:30" },
      { display: "06:45", value: "06:45" },
      { display: "07:00", value: "07:00" },
      { display: "07:15", value: "07:15" },
      { display: "07:30", value: "07:30" },
      { display: "07:45", value: "07:45" },
      { display: "08:00", value: "08:00" },
      { display: "08:15", value: "08:15" },
      { display: "08:30", value: "08:30" },
      { display: "08:45", value: "08:45" },
      { display: "09:00", value: "09:00" },
      { display: "09:15", value: "09:15" },
      { display: "09:30", value: "09:30" },
      { display: "09:45", value: "09:45" },
      { display: "10:00", value: "10:00" },
      { display: "10:15", value: "10:15" },
      { display: "10:30", value: "10:30" },
      { display: "10:45", value: "10:45" },
      { display: "11:00", value: "11:00" },
      { display: "11:15", value: "11:15" },
      { display: "11:30", value: "11:30" },
      { display: "11:45", value: "11:45" },
      { display: "12:00", value: "12:00" },
      { display: "12:15", value: "12:15" },
      { display: "12:30", value: "12:30" },
      { display: "12:45", value: "12:45" },
      { display: "13:00", value: "13:00" },
      { display: "13:15", value: "13:15" },
      { display: "13:30", value: "13:30" },
      { display: "13:45", value: "13:45" },
      { display: "14:00", value: "14:00" },
      { display: "14:15", value: "14:15" },
      { display: "14:30", value: "14:30" },
      { display: "14:45", value: "14:45" },
      { display: "15:00", value: "15:00" },
      { display: "15:15", value: "15:15" },
      { display: "15:30", value: "15:30" },
      { display: "15:45", value: "15:45" },
      { display: "16:00", value: "16:00" },
      { display: "16:15", value: "16:15" },
      { display: "16:30", value: "16:30" },
      { display: "16:45", value: "16:45" },
      { display: "17:00", value: "17:00" },
      { display: "17:15", value: "17:15" },
      { display: "17:30", value: "17:30" },
      { display: "17:45", value: "17:45" },
      { display: "18:00", value: "18:00" },
      { display: "18:15", value: "18:15" },
      { display: "18:30", value: "18:30" },
      { display: "18:45", value: "18:45" },
      { display: "19:00", value: "19:00" },
      { display: "19:15", value: "19:15" },
      { display: "19:30", value: "19:30" },
      { display: "19:45", value: "19:45" },
      { display: "20:00", value: "20:00" },
      { display: "20:15", value: "20:15" },
      { display: "20:30", value: "20:30" },
      { display: "20:45", value: "20:45" },
      { display: "21:00", value: "21:00" },
      { display: "21:15", value: "21:15" },
      { display: "21:30", value: "21:30" },
      { display: "21:45", value: "21:45" },
      { display: "22:00", value: "22:00" },
      { display: "22:15", value: "22:15" },
      { display: "22:30", value: "22:30" },
      { display: "22:45", value: "22:45" },
      { display: "23:00", value: "23:00" },
      { display: "23:15", value: "23:15" },
      { display: "23:30", value: "23:30" },
      { display: "23:45", value: "23:45" },
      { display: "Midnight", value: "24:00" }
    ]

  public searchAddressResult: any;

  public dataLoading: boolean = false;
  public mapContainer: Map;
  public isMapLoaded: boolean = false;
  public mapPoints: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: 'FeatureCollection',
    features: []
  };
  public markerTypes = mapMarkerTypes
  public mapZoomDefault: number = 13;
  public mapLatDefault: number = 39.396662
  public mapLngDefault: number = -97.005892

  mapEnable(map: Map, enableValue: boolean) {
    if (!map) return;

    if (enableValue) {
      map.scrollZoom.enable();
      map.boxZoom.enable();
      //map.dragRotate.enable();
      map.dragPan.enable();
      map.doubleClickZoom.enable();
      //map.touchZoomRotate.enable();
    }
    else {
      //map.scrollZoom.disable();
      //map.boxZoom.disable();
      map.dragRotate.disable();
      //map.dragPan.disable();
      //map.doubleClickZoom.disable();
      map.touchZoomRotate.disable();
    }
    this.searchAddressResult = []
    this.doCenterAddress()
  }

  onMapLoad(mapInstance: Map) {
    this.isMapLoaded = true
    this.mapContainer = mapInstance
    this.mapEnable(this.mapContainer, false)

    console.log(this.mglMap)
    // if (this.mapPoints && this.mapPoints.features.length == 0)
    //   this.buttonMapUS(null)
    // else this.buttonMapFit(null)

    var self = this;
    setTimeout(function () {
      //self.getPrevLocsAll()
    }, 25);
  }

  getMarkerSvg(): string {

    if (this.device.connectivityTypeId == 0o0) {
      return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.Wifi)
    }
    else {
      return this.mappingService.getMarkerTypeSvg(mapMarkerTypes.Cellular)
    }
  }
  markerLocClick(event) {
    //alert("Woo-Hoo!")
    console.log(event)
  }
  markerPrevLocClick(loc: any, $event) {
    if (!this.isEditMode) return;

    this.fgDeviceLocation.patchValue({
      latitude: loc.latitude,
      longitude: loc.longitude,
      address: loc.address,
      city: loc.city,
      stateProvinceCode: loc.stateProvinceCode,
      postalCode: loc.postalCode,
      country: loc.country
    })

    this.doCenterAddress()
  }
  markerSearchAddrClick(addr: any, $event) {
    if (!this.isEditMode) return;

    var streetAddress: string =
      ((addr.address.houseNumber ? addr.address.houseNumber.toString().trim() : "") + " " +
        (addr.address.street ? addr.address.street.toString().trim() : "")).trim()

    this.fgDeviceLocation.patchValue({
      latitude: addr.position.lat,
      longitude: addr.position.lng,
      address: streetAddress != "" ? streetAddress : this.fgDeviceLocation.get("address").value,
      city: addr.address.city,
      stateProvinceCode: addr.address.state,
      postalCode: addr.address.postalCode,
      country: addr.address.countryCode
    })

    //this.searchAddressResult = []
    this.doCenterAddress()
  }

  markerLocDragEnd(event) {
    let mrkr: Marker = event;

    this.fgDeviceLocation.patchValue(
      {
        latitude: mrkr.getLngLat().lat.toFixed(6),
        longitude: mrkr.getLngLat().lng.toFixed(6)
      }
    )
    this.reverseGeocodeAddress(mrkr.getLngLat().lat, mrkr.getLngLat().lng)
  }

  onLocationModified(value: boolean) {
    this.locationModified.emit(value);
  }

  openLocationDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
  }

  LocationDeleteConfirm() {
    //alert(this.fgDeviceLocation.value.uuid)

    if (this.fgDeviceLocationSetting.value &&
      this.fgDeviceLocationSetting.value.id > 0) {

      return this.deviceLocationSettingsService.deleteById(this.fgDeviceLocationSetting.value.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.notificationsService.success('Success', 'Device Location Setting Deleted', { timeOut: 2000, clickToClose: true });
            this.modalRef.hide();
            this.getDeviceLocationSettings()
            this.onLocationModified(result);
            this.formState = this.formStates.Cancelled
          },
          error => {
            this.notificationsService.error("Server Error (LocationDeleteConfirm)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
            this.modalRef.hide();
          });
    }
    this.modalRef.hide();
  }

  LocationDeleteCancel(): void {
    this.modalRef.hide();
  }

  get currentDate(): Date {
    return new Date();
  }

  get isEditMode(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
      case this.formStates.Save:
      case this.formStates.Saving:
      case this.formStates.Saved:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }
  get isMapEnabled(): boolean {
    // if (this.formState == DeviceWifiFormState.New) return true
    // if (this.formState == DeviceWifiFormState.Edit) return true

    switch (this.formState) {
      case this.formStates.New:
      case this.formStates.Edit:
        {
          return true
        }
      default:
        {
          return false
        }
    }
  }

  public formEnabled = false;
  public formStates = DeviceLocationsFormState
  private _formState: DeviceLocationsFormState = DeviceLocationsFormState.Initializing
  get formState(): DeviceLocationsFormState {
    return this._formState
  }
  @Input() set formState(newFormState: DeviceLocationsFormState) {

    var updateFormState: DeviceLocationsFormState = null
    this.fgDeviceLocation.disable()
    this.fgDeviceLocationSetting.disable()

    switch (newFormState) {
      case this.formStates.Read: {
        updateFormState = newFormState
        this.mapEnable(this.mapContainer, false)
        this.deviceLocationSelect()
        break
      }
      case this.formStates.New: {
        this.fgDeviceLocation.enable()
        this.fgDeviceLocationSetting.enable()
        this.getDeviceLocationsAll()
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 25);
        this.mapEnable(this.mapContainer, true)
        updateFormState = newFormState
        break
      }
      case this.formStates.Edit: {
        updateFormState = newFormState
        this.fgDeviceLocation.enable()
        this.fgDeviceLocationSetting.enable()
        this.deviceLocationSettingsEdit()
        this.mapEnable(this.mapContainer, true)
        break
      }
      case this.formStates.Save: {
        //presetting to address form state sequencing durring validation
        this.fgDeviceLocation.enable()
        this.fgDeviceLocationSetting.enable()
        this._formState = newFormState
        this.deviceLocationSave()
        this.mapEnable(this.mapContainer, false)
        //this.deviceLocationSettingsSave()
        break
      }
      case this.formStates.Saving: {
        this.fgDeviceLocation.enable()
        this.fgDeviceLocationSetting.enable()
        this.mapEnable(this.mapContainer, false)
        break
      }
      case this.formStates.Cancel: {
        this.deviceLocationSettingsCancel()
        updateFormState = this.formStates.Read
        this.mapEnable(this.mapContainer, false)
        this.doCenterAddress()
        break
      }
      case this.formStates.Cancelled: {
        this.deviceLocationSettingsBack()
        this.formState = this.formStates.List
        this.mapEnable(this.mapContainer, false)
        this.doCenterAddress()
        break
      }
      default:
        {
          updateFormState = newFormState
          this.mapEnable(this.mapContainer, false)
        }
    }

    if (updateFormState != null) this._formState = updateFormState
  }

  private _deviceUuid: string = "";
  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {
    var updateLocations: boolean = (this._deviceUuid != newDeviceUuid);
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      if (!updateLocations && this.deviceLocationSettings.length == 0) updateLocations = true;
      this._deviceUuid = newDeviceUuid;
    }
    else {
      this._deviceUuid = "";
      if (!updateLocations && this.deviceLocationSettings.length > 0) updateLocations = true;
    }
    if (updateLocations) this.getDeviceLocationSettingsDelayed()
  }

  public fgDeviceLocationSetting: FormGroup = this.formBuilder.group({
    //deviceLocationSetting: null,
    id: 0,
    deviceLocationUuid: "",
    deviceUuid: "",
    recordDate: [null, [Validators.required]],
    unitIdSpeed: "0",
    speedLimit: [0, [Validators.required,
    function (control: AbstractControl) {
      if (!control.parent) return null
      if (
        (control.parent.value['unitIdSpeed'] == "0" && control.value >= 5 && control.value <= 70) ||
        (control.parent.value['unitIdSpeed'] == "1" && control.value >= 10 && control.value <= 120)
      ) {
        return null
      }
      return { speedLimit: false }
    }
    ]],
    speedLimitSecondary: [0, [Validators.required,
    function (control: AbstractControl) {
      if (!control.parent) return null
      if (control.value == 0 ||
        (control.parent.value['unitIdSpeed'] == "0" && control.value >= 5 && control.value <= 70) ||
        (control.parent.value['unitIdSpeed'] == "1" && control.value >= 10 && control.value <= 120)
      ) {
        return null
      }
      return { speedLimitSecondary: false }
    }
    ]],
    speedLimitSecondaryTime1Begin: "Not Set",
    speedLimitSecondaryTime1End: "Not Set",
    speedLimitSecondaryTime2Begin: "Not Set",
    speedLimitSecondaryTime2End: "Not Set",
    device: null,
    deviceLocation: null,
  });

  public fgLocationSettings_Reset() {
    this.fgDeviceLocationSetting.patchValue(
      {
        id: 0,
        deviceLocationUuid: "",
        deviceUuid: "",
        recordDate: null,
        //unitIdSpeed: "0",
        speedLimit: 0,
        speedLimitSecondary: 0,
        speedLimitSecondaryTime1Begin: "",
        speedLimitSecondaryTime1End: "",
        speedLimitSecondaryTime2Begin: "",
        speedLimitSecondaryTime2End: "",
        device: null,
        deviceLocation: null
      }
    )
  }

  public fgDeviceLocation: FormGroup = this.formBuilder.group({
    uuid: "",
    clientUuid: "",
    bearingId: [null, [
      function (control: AbstractControl) {
        if (control.value != null && control.value >= 0) {
          return null
        }
        return { bearingId: false };;
      }
    ]],
    locationTypeId: 0,
    utcTimeOffset: 0,
    latitude: 0,
    longitude: 0,
    name: ['', [Validators.required, Validators.minLength(10)]],
    address: "",
    city: "",
    stateProvinceCode: "",
    postalCode: ["", [Validators.required]],
    country: [null, [
      function (control: AbstractControl) {
        if (control.value && control.value.toString().trim() != "") {
          return null
        }
        return { country: false };;
      }
    ]],
    unitIdSpeed: "0"
  });

  public fgDeviceLocation_Reset() {
    this.fgDeviceLocation.patchValue(
      {
        uuid: "",
        clientUuid: "",
        bearingId: 0,
        locationTypeId: 0,
        utcTimeOffset: 0,
        latitude: 0,
        longitude: 0,
        name: "",
        address: "",
        city: "",
        stateProvinceCode: "",
        postalCode: "",
        country: null,
        unitIdSpeed: "0"
      }
    )
  }

  ngOnInit() {

    this.fgDeviceLocation.get('country').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(val => {

      if (val) {
        console.log(this.librariesService.isCountryMPH(val))
        this.fgDeviceLocation.patchValue({ unitIdSpeed: this.librariesService.isCountryMPH(val) ? "0" : "1" })
        this.fgDeviceLocationSetting.patchValue({ unitIdSpeed: this.librariesService.isCountryMPH(val) ? "0" : "1" })
      } else {
        this.fgDeviceLocation.patchValue({ unitIdSpeed: this.librariesService.isCountryMPH(val) ? "0" : "1" })
        this.fgDeviceLocationSetting.patchValue({ unitIdSpeed: "0" });
      }

    });

    this.paramSub = this.route.params.subscribe(params => {
      this.deviceUuid = params['uuid'];
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getCountries();
      this.getBearings();
    }, 500);
  }

  getDeviceLocationSettingsDelayed() {
    setTimeout(() => {
      console.log("getDeviceLocationSettingsDelayed")
      this.getDeviceLocationSettings()
      this.deviceLocationsAll = [];

    }, 500);
  }

  public deviceLocationsAll: any[] = [];
  getDeviceLocationsAll(): any {

    this.deviceLocationsAll = [];
    //*** REMOVED, need similar function: this.removeDeviceLocationsAll()

    if (!this.settingsService.client || !this.deviceUuid) return;

    return this.deviceLocationsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceLocationsAll = result.sort((a, b) => a.latitude > b.latitude ? -1 : a.latitude < b.latitude ? 1 : 0);

          //*** REMOVED, need similar function: this.displayDeviceLocationsAll()
        },
        error => {
          this.notificationsService.error("Server Error (getDeviceLocationsAll)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }


  public deviceLocationSetting: any;
  public deviceLocationSettingsTemp: any[] = [];
  public deviceLocationSettings: any[] = [];
  public deviceLocationSettingsSelected: any[] = [];
  getDeviceLocationSettings(): any {

    console.log('FG DEVICELOCATION SETTING', this.fgDeviceLocationSetting);
    console.log('FG DEVICE LOCATION ONLY', this.fgDeviceLocation);

    this.deviceLocationSetting = null;
    this.deviceLocationSettingsTemp = [];
    this.deviceLocationSettings = [];
    this.deviceLocationSettingsSelected = [];

    if (!this.settingsService.client || !this.deviceUuid) {
      this.getDeviceLocationSettingsDelayed();
      return;
    }

    console.log("getDeviceLocationSettingsDelayed: readByDeviceUuid")
    return this.deviceLocationSettingsService.readByDeviceUuid(this.deviceUuid, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceLocationSettings = result;
          this.deviceLocationSettingsTemp = result;
          // this.formState = this.formStates.Read;
          console.log('end form', this.formState)
        },
        error => {
          this.notificationsService.error("Server Error (getDeviceLocationSettings)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  countries: any[] = [];
  getCountries(): any {
    this.countries = [];

    if (!this.settingsService.client) return;

    //alert(this.deviceUuid);

    return this.countriesService.readByRank()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.countries = result;
        },
        error => {
          this.notificationsService.error("Server Error (getCountries)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  bearings: any[] = [];
  getBearings(): any {
    this.bearings = [];

    if (!this.settingsService.client) return;

    //alert(this.deviceUuid);

    return this.bearingsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.bearings = result;
        },
        error => {
          this.notificationsService.error("Server Error (bearingsService)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.deviceLocationSettingsTemp.filter(function (d) {
      return ((d.name != null && d.name.toLowerCase().indexOf(val) !== -1) ||
        (d.address != null && d.address.toLowerCase().indexOf(val) !== -1) ||
        (d.city != null && d.city.toLowerCase().indexOf(val) !== -1) ||
        (d.stateProvinceCode != null && d.stateProvinceCode.toLowerCase().indexOf(val) !== -1) ||
        (d.postalCode != null && d.postalCode.toLowerCase().indexOf(val) !== -1) ||
        !val);
    });
    // update the rows
    this.deviceLocationSettings = temp;
    // Whenever the filter changes, always go back to the first page
    this.dataTable.offset = 0;
  }


  getLocationAddressDisplay(activity: any): string {
    if (!location) return ''
    //location = {city: 'hello' ,postalCode: 'goodbye'  }

    var display: string = activity.deviceLocation.city || ''

    if (display.length > 0 && activity.deviceLocation.stateProvinceCode && activity.deviceLocation.stateProvinceCode.length > 0) display = display + ', '
    display = display + (activity.deviceLocation.stateProvinceCode || '')

    if (display.length > 0 && activity.deviceLocation.postalCode && activity.deviceLocation.postalCode.length > 0) display = display + ', '
    display = display + (activity.deviceLocation.postalCode || '')

    if (display.length > 0 && activity.deviceLocation.country && activity.deviceLocation.country.length > 0) display = display + ', '
    display = display + (activity.deviceLocation.country || '')

    return display
  }

  onDeviceLocationSelect(event) {


    if (event && event.data.id) {
      console.log('onDeviceLocationSelect Update Selected to ' + event.data.id, event);
      this.deviceLocationSetting = event.data;
      this.onDeviceLocationSelected.emit(event.data.id);
      this.formState = this.formStates.Read

      this.doCenterAddress()
    }
  }

  timeSpanMapIn(timeSpan: string): string {
    if (!timeSpan || timeSpan.toString().trim() == "") return "Not Set"
    return timeSpan.toString().trim()
  }

  deviceLocationSelectionClear() {
    //this.formState = this.formStates.Edit

    this.fgDeviceLocation.patchValue(
      {
        uuid: ""
      })

    this.fgDeviceLocationSetting.patchValue(
      {
        id: 0,
        recordDate: null
      })

    console.log("deviceLocationSelectionClear")
  }

  deviceLocationSelect() {
    //this.formState = this.formStates.Edit

    if (this.deviceLocationSetting) {
      var dl: any = this.deviceLocationSetting.deviceLocation
      this.deviceLocationSettingsPatch(this.deviceLocationSetting)
      this.deviceLocationPatch(dl)
    }

    this.doCenterAddress()

    console.log("deviceLocationSelect");
    console.log("DEVICE LOCATION SELECT", this.formState);
  }
  deviceLocationPatch(dLoc: any) {
    this.fgDeviceLocation.patchValue(
      {
        uuid: dLoc.uuid,
        clientUuid: dLoc.clientUuid,
        bearingId: dLoc.bearingId,
        locationTypeId: dLoc.locationTypeId,

        utcTimeOffset: dLoc.utcTimeOffset,
        latitude: dLoc.latitude,
        longitude: dLoc.longitude,
        name: dLoc.name,
        address: dLoc.address,
        city: dLoc.city,
        stateProvinceCode: dLoc.stateProvinceCode,
        postalCode: dLoc.postalCode,
        country: dLoc.country,
        unitIdSpeed: dLoc.unitIdSpeed.toString(),
      }
    )
  }
  deviceLocationSettingsPatch(dLocSet) {
    this.fgDeviceLocationSetting.patchValue(
      {
        id: dLocSet.id,
        deviceLocationUuid: dLocSet.deviceLocationUuid,
        deviceUuid: dLocSet.deviceUuid,
        recordDate: (dLocSet.recordDate ? new Date(dLocSet.recordDate) : null),
        //unitIdSpeed: dLocSet.unitIdSpeed.toString(),
        speedLimit: dLocSet.speedLimit,
        speedLimitSecondary: dLocSet.speedLimitSecondary,
        speedLimitSecondaryTime1Begin: dLocSet.speedLimitSecondaryTime1Begin ? dLocSet.speedLimitSecondaryTime1Begin : "",
        speedLimitSecondaryTime1End: dLocSet.speedLimitSecondaryTime1End ? dLocSet.speedLimitSecondaryTime1End : "",
        speedLimitSecondaryTime2Begin: dLocSet.speedLimitSecondaryTime2Begin ? dLocSet.speedLimitSecondaryTime2Begin : "",
        speedLimitSecondaryTime2End: dLocSet.speedLimitSecondaryTime2End ? dLocSet.speedLimitSecondaryTime2End : ""
      }
    )
  }


  deviceLocationSave() {

    if (!this.fgDeviceLocation.valid) {
      this.librariesService.validateFormGroup(this.fgDeviceLocation)
      this.formState = this.fgDeviceLocation.get("uuid").value == "" ? this.formStates.New : this.formStates.Edit
      this.onFormEventDevice.emit("edit")
      this.notificationsService.warn("Validation Warning (DeviceLocation)", "Verify Required Data", { timeOut: 5000, clickToClose: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEventDevice.emit("saving")

    if (this.fgDeviceLocation.get("uuid").value == "") {
      return this.deviceLocationsService.create(this.fgDeviceLocation.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            //alert(result.toString())

            this.deviceLocationPatch(result)
            //this.deviceLocationSetting.deviceLocation = result

            this.fgDeviceLocationSetting.patchValue(
              {
                deviceLocationUuid: result.uuid,
                deviceLocation: result
              })

            this.deviceLocationSettingSave()
          },
          error => {
            this.notificationsService.error("Server Error (deviceLocationSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });
    }
    else if (this.librariesService.guidValidate(this.fgDeviceLocation.get("uuid").value)) {
      return this.deviceLocationsService.put(this.fgDeviceLocation.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.deviceLocationPatch(result)

            this.fgDeviceLocationSetting.patchValue(
              {
                deviceLocationUuid: result.uuid,
                deviceLocation: result
              })

            this.deviceLocationSettingSave()
          },
          error => {
            this.notificationsService.error("Server Error (deviceLocationSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });

    }
  }

  deviceLocationSettingSave() {

    if (!this.fgDeviceLocationSetting.valid) {
      this.librariesService.validateFormGroup(this.fgDeviceLocationSetting)
      this.formState = this.fgDeviceLocationSetting.get("id").value == 0 ? this.formStates.New : this.formStates.Edit
      this.onFormEventDevice.emit("edit")
      this.notificationsService.warn("Validation Warning (DeviceLocationSetting)", "Verify Required Data", { timeOut: 5000, clickToClose: true });
      return
    }

    this.formState = this.formStates.Saving
    this.onFormEventDevice.emit("saving")

    // var rDate: Date = this.fgDeviceLocationSetting.get("recordDate").value
    // var sDate: string = rDate.getFullYear()+"-"+(rDate.getMonth()+1)+"-"+(rDate.getDay()+1)
    // alert(sDate)
    this.fgDeviceLocationSetting.patchValue(
      {
        deviceUuid: this.deviceUuid,
        recordDate: this.datePipe.transform(
          this.fgDeviceLocationSetting.get("recordDate").value, "yyyy-MM-dd"
        )
      }
    )
    console.log(this.fgDeviceLocationSetting.value)
    if (this.fgDeviceLocationSetting.get("id").value == 0) {
      return this.deviceLocationSettingsService.create(this.fgDeviceLocationSetting.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {
            //alert(result.toString())

            this.deviceLocationSettingsPatch(result)
            this.deviceLocationSetting = result
            this.formState = this.formStates.Read
            this.getDeviceLocationSettings()
            this.onLocationModified(result);

            

            this.notificationsService.success('Success', 'Device Location Information Saved', { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.notificationsService.error("Server Error (deviceLocationSettingSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });
    }
    
    else if (this.fgDeviceLocationSetting.get("id").value > 0) {
      return this.deviceLocationSettingsService.put(this.fgDeviceLocationSetting.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: any) => {

            this.deviceLocationSettingsPatch(result)
            this.deviceLocationSetting = result
            this.formState = this.formStates.Read
            this.getDeviceLocationSettings()

            this.notificationsService.success('Success', 'Device Location Information Saved', { timeOut: 2000, clickToClose: true });
          },
          error => {
            this.notificationsService.error("Server Error (deviceLocationSettingSave)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
            this.formState = this.formStates.Edit
          });
          
    }
  }


  deviceLocationSettingsBack() {
    this.deviceLocationSettingsSelected = [];
    this.deviceLocationSetting = null;
    this.fgDeviceLocation_Reset()
    this.fgLocationSettings_Reset()
    console.log("deviceLocationSettingsBack")
  }

  deviceLocationSettingsAdd() {
    this.fgDeviceLocation_Reset
    this.fgLocationSettings_Reset
    console.log("deviceLocationSettingsAdd")
  }

  deviceLocationSettingsEdit() {
    this.librariesService.validateFormGroup(this.fgDeviceLocation)
    this.librariesService.validateFormGroup(this.fgDeviceLocationSetting)
    this.getDeviceLocationsAll()
  }

  deviceLocationSettingsDelete() {
    console.log("deviceLocationSettingsDelete")
  }

  deviceLocationSettingsSave() {
    console.log("deviceLocationSettingsSave")
  }

  deviceLocationSettingsCancel() {

    this.fgDeviceLocation_Reset
    this.fgLocationSettings_Reset
    this.deviceLocationSelect()

    console.log("deviceLocationSettingsCancel")
  }

  controlValidationClass(control) {
    //console.log(control)
    return "form-control" + (this.controlInvalidFlag(control) != null ? (this.controlInvalidFlag(control) ? " is-invalid" : " is-valid") : "")

  }
  controlInvalidFlag(control) {
    if (!(control.touched)) return null;
    return control.invalid
  }

  doSearchAddressFreeform() {

    this.searchAddressResult = []
    if (!this.settingsService.client) return;

    //this.mapLocationMarker.setVisibility(false)
    this.fgDeviceLocation.patchValue({ latitude: 0, longitude: 0 })
    this.fgDeviceLocationSetting.patchValue({ recordDate: null })

    return this.mappingService.searchAddressFreeform(
      this.fgDeviceLocation.get('address').value,
      this.fgDeviceLocation.get('city').value,
      this.fgDeviceLocation.get('stateProvinceCode').value,
      this.fgDeviceLocation.get('postalCode').value,
      this.fgDeviceLocation.get('country').value
    )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          //alert("searchAddressFreeform: "+result.Response.MetaInfo.Timestamp)
          this.searchAddressResult = result.items;
          this.displaySearchAddressFreeform()
        },
        error => {
          this.notificationsService.error("Server Error (doSearchAddressFreeform)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });

  }

  displaySearchAddressFreeform() {
    if (this.searchAddressResult.length > 0) {
      this.doCenterAddress(
        this.searchAddressResult[0].position.lat,
        this.searchAddressResult[0].position.lng
      )
    }
    else {
      return;
    }
  }


  doCenterAddress(lat: number = 0, lng: number = 0) {
    var self = this;
    setTimeout(() => {

      self.mglMap.mapInstance.resize()
      if (lat != 0 && lng != 0) {
        self.mglMap.mapInstance.panTo([lng, lat])
      }
      else if (self.fgDeviceLocation.get('longitude').value != 0) {

        self.mglMap.mapInstance.setZoom(self.mapZoomDefault)

        if (self.markerLoc) {
          let mrk: Marker = self.markerLoc.markerInstance
          mrk.setLngLat([self.fgDeviceLocation.get('longitude').value, self.fgDeviceLocation.get('latitude').value])
        }

        let mrkr = document.getElementById('markerLoc')
        if (mrkr) mrkr.parentElement.style.zIndex = "100"

        self.mglMap.mapInstance.panTo(
          [self.fgDeviceLocation.get('longitude').value, self.fgDeviceLocation.get('latitude').value]

        )
      }
    }, 25);
  }

  reverseGeocodeAddress(lat: number, lng: number) {

    if (!this.settingsService.client) return;

    return this.mappingService.reverseGeocode(lat, lng)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {

          result = result.items;

          if (result && result.length > 0 && result[0].distance && result[0].distance < 300 && result[0].address) {

            var loc: any = result[0].address

            var streetAddress: string =
              ((loc.houseNumber ? loc.houseNumber.toString().trim() : "") + " " +
                (loc.street ? loc.street.toString().trim() : "")).trim()

            this.fgDeviceLocation.patchValue({
              //latitude: loc.DisplayPosition.Latitude.toFixed(6),
              //longitude: loc.DisplayPosition.Longitude.toFixed(6),
              address: streetAddress,
              city: loc.city,
              stateProvinceCode: loc.stateCode,
              postalCode: loc.postalCode,
              country: loc.countryCode
            })

          }

        },
        error => {
          this.notificationsService.error("Server Error (reverseGeocodeAddress)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });

  }

  addNewLocation(event) {
    this.formState = this.formStates.New;
    console.log('DeviceLocation: ', this.fgDeviceLocation)
    console.log('DeviceLocationSetting: ', this.fgDeviceLocationSetting)
    this.fgDeviceLocation_Reset();
  }


  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }


}
