<ag-grid-angular 
    style="height: 70vh; width: 100%;" 
    class="ag-theme-alpine m-0" 
    [rowData]="gridData"
    [getDetailRowData]="true" 
    [gridOptions]="gridOptions" 
    [animateRows]="true"  
    [suppressCellSelection]="true" 
    [suppressScrollOnNewData]="false"
    >
</ag-grid-angular>