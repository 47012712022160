
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { DeviceLocationSettingsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { LibrariesService } from '../../../../core/libraries/libraries.service';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject } from 'rxjs';
import { GridOptions, ColumnApi } from 'ag-grid-community';


@Component({
  selector: 'app-devicelocationsettingsview',
  templateUrl: './devicelocationsettingsview.component.html',
  styleUrls: ['./devicelocationsettingsview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceLocationSettingsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onDeviceLocationSelected: EventEmitter<any> = new EventEmitter()
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter()
  @ViewChild('deviceLocationSettingsTable') dataView: DatatableComponent;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();
  public gridOptions: GridOptions;
  public chartThemes;
  private columnApi: ColumnApi;

  //public viewInit: boolean = false;
  public deviceLocationSettingsTemp: any[] = [];
  public deviceLocationSettings: any[] = [];
  public deviceLocationSettingsSelected: any[] = [];

  datePipe = new DatePipe('en-US');
  decimalPipe = new DecimalPipe('en-US');

  private _deviceUuid: string = "";
  get deviceUuid(): string {
    return this._deviceUuid;
  }
  set deviceUuid(newDeviceUuid: string) {
    if (this.librariesService.guidValidate(newDeviceUuid)) {
      this._deviceUuid = newDeviceUuid;
      //this.getDeviceLocationSettings();
    }
    else {
      this._deviceUuid = "";
      this.deviceLocationSettingsTemp = [];
      this.deviceLocationSettings = [];
      this.deviceLocationSettingsSelected = [];
    }
  }

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public deviceLocationSettingsService: DeviceLocationSettingsService,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService) {
      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
     
      this.gridOptions = <GridOptions>{
        rowData: [],
        columnDefs: this.columnDefs,
        rowSelection: 'multiple',
        enableCharts: false,
        enableRangeSelection: false
        }
  }

  columnDefs = [
    {
     colId: '1', headerName: "Date", field: "recordDate", sortable: true, resizable: true, suppressMenu: true,
     cellRenderer: (data) => {
      return data.value ? (data.value != null
        ? (new Date(data.value)).toLocaleDateString()
        : '') : '';
      }
    },
    { colId: '2', headerName: "Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
    { colId: '3', headerName: "Street Address", field: "deviceLocation.address", sortable: true, resizable: true, suppressMenu: true },
    { colId: '4', headerName: "City", field: "deviceLocation.city", sortable: true, resizable: true, suppressMenu: true },
    { colId: '5', headerName: "State/Prov", field: "deviceLocation.stateProvinceCode", sortable: true, resizable: true, suppressMenu: true },
    { colId: '6', headerName: "Country", field: "deviceLocation.country", sortable: true, resizable: true, suppressMenu: true },
    { colId: '7', headerName: "Latitude", field: "deviceLocation.latitude", sortable: true, resizable: true, suppressMenu: true },
    { colId: '0', headerName: "Longitude", field: "deviceLocation.longitude", sortable: true, resizable: true, flex: 1, suppressMenu: true }
  ]

  onGridReady(params: any) {
    setTimeout(() => {
      if (this.gridOptions != null) {
        this.gridOptions.api.setRowData(this.deviceLocationSettings);
        this.columnApi = params.columnApi;
        const allColumnIds = this.columnApi.getAllColumns().map(column => column.getColDef().colId).filter(col => col !== '0');
        this.columnApi.autoSizeColumns(allColumnIds);
      }
      
    }, 2200);
    
  }

  ngOnInit() {
    this.getDeviceLocationSettings();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    
  }

  clearDeviceLocationSettings() {

    this.deviceLocationSettings = [];
    this.deviceLocationSettings.length = 0
    this.deviceLocationSettingsTemp = [];
    this.deviceLocationSettingsTemp.length = 0
    this.deviceLocationSettingsSelected = [];
    this.deviceLocationSettingsSelected.length = 0

  }

  getDeviceLocationSettings(): any {

    if (!this.settingsService.client || this.deviceUuid == "") return;

    //alert(this.deviceUuid);

    return this.deviceLocationSettingsService.readByDeviceUuid(this.deviceUuid, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.deviceLocationSettings = result;
          this.deviceLocationSettingsTemp = result;
        },
        error => {
          this.notificationsService.error("Server Error (getDeviceLocationSettings)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.deviceLocationSettingsTemp.filter(function (d) {
      return ((d.name != null && d.name.toLowerCase().indexOf(val) !== -1) ||
        (d.address != null && d.address.toLowerCase().indexOf(val) !== -1) ||
        (d.city != null && d.city.toLowerCase().indexOf(val) !== -1) ||
        (d.stateProvinceCode != null && d.stateProvinceCode.toLowerCase().indexOf(val) !== -1) ||
        (d.postalCode != null && d.postalCode.toLowerCase().indexOf(val) !== -1) ||
        !val);
    });
    // update the rows
    this.deviceLocationSettings = temp;
    // Whenever the filter changes, always go back to the first page
    this.dataView.offset = 0;
  }

  getLocationAddressDisplay(location: any): string {
    if (!location) return ''
    //location = {city: 'hello' ,postalCode: 'goodbye'  }

    var display: string = location.city || ''

    if (display.length > 0 && location.stateProvinceCode && location.stateProvinceCode.length > 0) display = display + ', '
    display = display + (location.stateProvinceCode || '')

    if (display.length > 0 && location.postalCode && location.postalCode.length > 0) display = display + ', '
    display = display + (location.postalCode || '')

    if (display.length > 0 && location.country && location.country.length > 0) display = display + ', '
    display = display + (location.country || '')

    return display
  }

  streetWindow() {
    window.open("https://www.google.com/maps?q=" + this.deviceLocationSettings[0].deviceLocation.latitude +"," + this.deviceLocationSettings[0].deviceLocation.longitude)
  }

  onSelect({ selected }) {
    //this.deviceLocationSettingsSelected.splice(0, this.deviceLocationSettingsSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onDeviceLocationSelected.emit(selected[0].id);
      //this.router.navigate(['/administration/registered-deviceLocationSettings', selected[0].uuid])
    }
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

}
