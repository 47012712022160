import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { FileUploadModule } from 'ng2-file-upload';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { ColorsService } from '../core/colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';

import { TabsControlComponent } from './component/controlsUi/tabsControl/tabsControl.component';
import { TabControlComponent } from './component/controlsUi/tabsControl/tabControl.component';
import { DynamicTabsDirective } from './component/controlsUi/tabsControl/dynamic-tabs.directive';

import { LoginComponent } from './component/login/login.component';
import { ResetPasswordComponent } from './component/resetpassword/resetpassword.component';
import { NewPasswordComponent } from './component/newpassword/newpassword.component';
import { RegisterComponent } from './component/register/register.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { OverviewComponent } from '../routes/overview/overview.component';
import { AlertsComponent } from '../routes/alerts/alerts.component';
import { AnalyticsComponent } from '../routes/analytics/analytics.component';
import { SettingsComponent } from '../routes/settings/settings.component';

import { DevicesCellularComponent } from '../routes/devices/devicescellular/devicescellular.component';
import { UploadComponent } from './component/upload/upload.component';
import { DeviceWifiFormComponent } from './component/forms/devices/devicewifi-form/devicewifi-form.component';
import { UsersAdminComponent } from '../routes/administration/users/users.component';
import { UsersAdminViewComponent } from './component/views/users-admin-view/users-admin-view.component';
import { UsersViewComponent } from './component/views/users-view/users-view.component';
import { UserFormComponent } from './component/forms/users/user-form/user-form.component';
import { UserClientFormComponent } from './component/forms/users/user-client-form/user-client-form.component';
import { UserProfileFormComponent } from './component/forms/users/user-profile-form/user-profile-form.component';
import { ClientsAdminComponent } from '../routes/administration/clients/clients.component';
import { ClientsAdminViewComponent } from './component/views/clients-admin-view/clients-admin-view.component';
import { ClientFormComponent } from './component/forms/clients/client-form/client-form.component';
import { DevicesAdminComponent } from '../routes/administration/devices/devices.component';
import { DevicesComponent } from '../routes/devices/devices.component';
import { UsersComponent } from '../routes/users/users.component';
import { DeviceStatusCardComponent } from './component/cards/device-status/device-status-card.component';
import { DeviceActivityStatsCardComponent } from './component/cards/device-activity-stats/device-activity-stats-card.component';
import { DevicesWifiComponent } from '../routes/devices/deviceswifi/deviceswifi.component';
import { DevicesViewComponent } from './component/views/devicesview/devicesview.component';
import { DeviceLocationsViewComponent } from './component/views/devicelocationsview/devicelocationsview.component';
import { DeviceConnectionsViewComponent } from './component/views/deviceconnectionsview/deviceconnectionsview.component';
import { DeviceTelemetriesViewComponent } from './component/views/devicetelemetriesview/devicetelemetriesview.component';
import { DeviceDataViewComponent } from './component/views/devicedataview/devicedataview.component';
import { TopAlertsViewComponent } from './component/views/topalertsview/topalertsview.component';
import { AlertsGridComponent } from './component/grids/alertsgrid/alertsgrid.component';
import { DevicesAvailableViewComponent } from './component/views/devicesavailable-view/devicesavailable-view.component';
import { DevicesSelectedViewComponent } from './component/views/devicesselected-view/devicesselected-view.component';
import { ConfigurationViewComponent } from './component/views/deviceconfigurationview/configurationview.component';
import { DeviceRegistrationsViewComponent } from './component/views/deviceregistrationsview/deviceregistrationsview.component';
import { DeviceAdministrationFormComponent } from './component/forms/devices/deviceadministration-form/deviceadministration-form.component';

import { ConfigurationsFormComponent } from './component/forms/configurations/configurations-form.component';
import { DeviceLocationsFormComponent } from './component/forms/devicelocations/devicelocations-form.component';
import { DeviceLocationSettingsViewComponent } from './component/views/devicelocationsettingsview/devicelocationsettingsview.component';
import { PopoverControlComponent } from './component/controlsUi/popoverControl/popoverControl.component';
import { HighlightControlComponent } from './component/controlsUi/highlightControl/highlightControl.component';

import { DailyEventSchedulesViewComponent } from './component/views/dailyeventschedules-view/dailyeventschedules-view.component';
import { DailyEventScheduleTimesViewComponent } from './component/views/dailyeventscheduletimes-view/dailyeventscheduletimes-view.component';

import { ConfigurationsComponent } from '../routes/configurations/configurations.component';
import { DaySchedulesCalendarFormComponent } from './component/forms/dayschedulescalendar/dayschedulescalendar-form.component';
import { DaySchedulesScheduleFormComponent } from './component/forms/dayschedulesschedule/dayschedulesschedule-form.component';
import { DaySchedulesSchedulesFormComponent } from './component/forms/dayschedulesschedules/dayschedulesschedules-form.component';
import { DaySchedulesTimeFormComponent } from './component/forms/dayschedulestime/dayschedulestime-form.component';
import { DaySchedulesTimeConfigFormComponent } from './component/forms/dayschedulestimeconfig/dayschedulestimeconfig-form.component';
import { DaySchedulesDefaultFormComponent } from './component/forms/dayschedulesdefault/dayschedulesdefault-form.component';

import { ChartsComponent } from '../routes/charts/charts.component';
import { MapComponent } from '../routes/map/map.component';
import { ChartsTimespanComponent } from '../routes/charts/timespan/charts-timespan.component';
import { ChartsSpeedGroupsComponent } from '../routes/charts/speedbins/charts-speedbins.component';
import { ChartsDataTablesComponent } from '../routes/charts/datatables/charts-datatables.component';
import { StatsVehiclesViolatorsChartComponent } from './component/charts/stats-vehiclesviolators-chart/stats-vehiclesviolators-chart.component';
import { StatsLawAbidersViolatorsChartComponent } from './component/charts/stats-lawabidersviolators-chart/stats-lawabidersviolators-chart.component';
import { StatsSpeedersPercentChartComponent } from './component/charts/stats-speederspercent-chart/stats-speederspercent-chart.component';
import { StatsSpeedAveragePeakChartComponent } from './component/charts/stats-speedaveragepeak-chart/stats-speedaveragepeak-chart.component';
import { SpeedBinsPercentChartComponent } from './component/charts/speedbins-percent-chart/speedbins-percent-chart.component';
import { SpeedBinsCountsChartComponent } from './component/charts/speedbins-counts-chart/speedbins-counts-chart.component';
import { SpeedBinsAverage85ChartComponent } from './component/charts/speedbins-average85-chart/speedbins-average85-chart.component';
import { DailySpeedSummaryVehicleCountsChartComponent } from './component/charts/daily-speedsummary-vehiclecounts-chart/daily-speedsummary-vehiclecounts-chart.component';
import { DailySpeedSummaryVehiclePercentagesChartComponent } from './component/charts/daily-speedsummary-vehiclepercentages-chart/daily-speedsummary-vehiclepercentages-chart.component';
import { UnderConstructionComponent } from './component/content/under-construction.component';
import { FirmwaresAdminComponent } from '../routes/administration/firmwares/firmwares.component';
import { FirmwaresAdminViewComponent } from './component/views/firmwares-admin-view/firmwares-admin-view.component';
import { FirmwareFormComponent } from './component/forms/firmwares/firmware-form/firmware-form.component';
import { ModemsAdminComponent } from '../routes/administration/modems/modems.component';
import { ModemsAdminViewComponent } from './component/views/modems-admin-view/modems-admin-view.component';
import { ModemFormComponent } from './component/forms/modems/modem-form/modem-form.component';
import { ModelsAdminComponent } from '../routes/administration/models/models.component';
import { ModelsAdminViewComponent } from './component/views/models-admin-view/models-admin-view.component';
import { ModelFormComponent } from './component/forms/models/model-form/model-form.component';
import { RoundPipe } from './pipes/round.pipe';
import { SafeHtmlPipe } from './pipes/safeHtmlPipe';
import { BootstrapYearCalendarComponent } from './component/calendar/bootstrap-year-calendar.component';

import { PeriodSummariesGridComponent } from './component/grids/periodsummaries-grid/periodsummaries-grid.component';
import { BinSummariesGridComponent } from './component/grids/binsummaries-grid/binsummaries-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ConfigOptionCardComponent } from './component/cards/config-option-card/config-option-card.component';
import { DeviceImagesViewComponent } from './component/views/deviceImages-view/deviceimagesview.component';
import { IntersectionObserverDirective } from '../core/directives/intersection-observer.directive';
import { ConfigOptionControlComponent } from './component/controlsUi/configOptionControl/configOptionControl.component';
import { SubscriptionsAdminComponent } from '../routes/administration/subscriptions/subscriptions.component';
import { SubscriptionsAdminViewComponent } from './component/views/subscriptions-admin-view/subscriptions-admin-view.component';
import { SubscriptionsFormComponent } from './component/forms/subscriptions/subscriptions-form/subscriptions-form.component';
import { SearchComponent } from '../routes/administration/search/search.component';






// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToasterModule.forRoot(), // may need to remove forRoot
        NgxSpinnerModule,
        NgxSelectModule,
        NgSelectModule,
        NgxDatatableModule,
        FileUploadModule,
        ChartsModule,
        IonRangeSliderModule,
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1IjoidGJhcnRvbiIsImEiOiJjamdteGE1MXQwd3o3MnFybWdoeG1zdmZ2In0.8sWOql5nI4-0Cc71fgawag',
            geocoderAccessToken: 'pk.eyJ1IjoidGJhcnRvbiIsImEiOiJjamdteGE1MXQwd3o3MnFybWdoeG1zdmZ2In0.8sWOql5nI4-0Cc71fgawag'
        }),
        AgGridModule.withComponents([])
    ],
    providers: [
        ColorsService,
        RoundPipe,
        SafeHtmlPipe
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        TabsControlComponent,
        TabControlComponent,
        DynamicTabsDirective,
        UploadComponent,
        LoginComponent,
        ResetPasswordComponent,
        NewPasswordComponent,
        RegisterComponent,
        OverviewComponent,
        DashboardComponent,
        AlertsComponent,
        AnalyticsComponent,
        SettingsComponent,
        ConfigOptionCardComponent,
        ConfigOptionControlComponent,
        DeviceStatusCardComponent,
        DeviceActivityStatsCardComponent,
        DevicesCellularComponent,
        DeviceWifiFormComponent,
        DeviceAdministrationFormComponent,
        UsersAdminComponent,
        UsersAdminViewComponent,
        UsersViewComponent,
        UserFormComponent,
        UserClientFormComponent,
        UserProfileFormComponent,
        FirmwaresAdminComponent,
        FirmwaresAdminViewComponent,
        IntersectionObserverDirective,
        FirmwareFormComponent,
        ModemsAdminComponent,
        ModemsAdminViewComponent,
        ModemFormComponent,
        ModelsAdminComponent,
        ModelsAdminViewComponent,
        PopoverControlComponent,
        HighlightControlComponent,
        ModelFormComponent,
        ClientsAdminComponent,
        ClientsAdminViewComponent,
        ClientFormComponent,
        ConfigurationViewComponent,
        DevicesAdminComponent,
        DevicesComponent,
        UsersComponent,
        ConfigurationsComponent,
        DevicesWifiComponent,
        DevicesViewComponent,
        ConfigurationViewComponent,
        DeviceRegistrationsViewComponent,
        DeviceLocationsViewComponent,
        DeviceConnectionsViewComponent,
        DeviceTelemetriesViewComponent,
        DeviceImagesViewComponent,
        DeviceDataViewComponent,
        TopAlertsViewComponent,
        AlertsGridComponent,
        DevicesAvailableViewComponent,
        DevicesSelectedViewComponent,
        DeviceLocationsFormComponent,
        DeviceLocationSettingsViewComponent,
        DaySchedulesScheduleFormComponent,
        DaySchedulesSchedulesFormComponent,
        DaySchedulesTimeFormComponent,
        DaySchedulesTimeConfigFormComponent,
        DaySchedulesDefaultFormComponent,
        DailyEventSchedulesViewComponent,
        DailyEventScheduleTimesViewComponent,
        DaySchedulesCalendarFormComponent,
        ConfigurationsFormComponent,
        ChartsComponent,
        MapComponent,
        ChartsTimespanComponent,
        ChartsSpeedGroupsComponent,
        ChartsDataTablesComponent,
        SearchComponent,
        StatsVehiclesViolatorsChartComponent,
        StatsLawAbidersViolatorsChartComponent,
        StatsSpeedersPercentChartComponent,
        StatsSpeedAveragePeakChartComponent,
        SubscriptionsAdminComponent,
        SubscriptionsAdminViewComponent,
        SubscriptionsFormComponent,
        SpeedBinsPercentChartComponent,
        SpeedBinsCountsChartComponent,
        SpeedBinsAverage85ChartComponent,
        DailySpeedSummaryVehicleCountsChartComponent,
        DailySpeedSummaryVehiclePercentagesChartComponent,
        PeriodSummariesGridComponent,
        BinSummariesGridComponent,
        UnderConstructionComponent,
        RoundPipe,
        SafeHtmlPipe,
        BootstrapYearCalendarComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        NgxSelectModule,
        NgSelectModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToasterModule,
        FlotDirective,
        SparklineDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        IonRangeSliderModule,
        RoundPipe
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
