import { Component, Output, OnInit, AfterViewInit,  OnDestroy, ViewChild } from '@angular/core';
import {filter,  takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService, userTypes, userRoles } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { ClientsService } from '../../core/api/api.services';
import { EventsService, EventTypes } from '../../core/events/events.service';
import { StatsLawAbidersViolatorsChartComponent } from '../../shared/component/charts/stats-lawabidersviolators-chart/stats-lawabidersviolators-chart.component';
import { LibrariesService } from '../../core/libraries/libraries.service';
import { Router, ActivatedRoute} from '@angular/router';

const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    //Observables
    private onDestroy$: Subject<void> = new Subject<void>();

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton') fsbutton;  // the fullscreen button


    @Output() get clients(): any {
        this.clientDropdown.disable();
        if (this.settingsService.clients && this.settingsService.clients.length > 0) {
            this.clientDropdown.enable();
            if (this.settingsService.client) {
                this.clientDropdown.setValue(this.settingsService.client.uuid)
            }
        }
        else {
            this.settingsService.clientSetAdmin = true;
            this.settingsService.client = null;
        }

        return this.settingsService.clients;
    }

    testguid = this.librariesService.guidCreate();


    public clientDropdown = new FormControl();
    public doSelect = (value: any) => {
        this.settingsService.client = this.settingsService.clients.filter(x => x.uuid == value)[0];
        this.settingsService.clientSetAdmin = false;
    }
    public removed = (value: any) => {

    }
    public typed = (value: any) => {

    }

    constructor(
        public authenticationService: AuthenticationService,
        public menu: MenuService,
        public userblockService: UserblockService,
        public notificationsService: NotificationsService,
        public settingsService: SettingsService,
        public eventsService: EventsService,
        public clientsService: ClientsService,
        public librariesService: LibrariesService,
        private router: Router,
        private route: ActivatedRoute
        ) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

        this.eventsService.events.pipe(filter(event =>event.type == this.eventsService.eventTypes.UserLogin))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                message => {
                    console.log("userLogin",this)
                }
            )

        //this.clientDropdown.valueChanges.subscribe(value => this.doSelect(value));
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    ngOnInit() {
        console.log(this.route.routeConfig.component.name+":ngOnInit", this)

        this.isNavSearchVisible = false;
        //console.log(this.clients)
    }

    ngAfterViewInit(){
        if (browser.msie) { // Not supported under IE
            var self = this
            setTimeout(function () {
                if (self.fsbutton) self.fsbutton.nativeElement.style.display = 'none';
            }, 1000);
        }
    }

    get getUserRoleIcon():string {
        //console.log('getUserRoleIcon')
        var roleIcon = "fa-user-alt-slash"

        let u = this.settingsService.user
        let ucr = this.settingsService.userClientRole

        if (ucr){

            switch(ucr.roleId) { 
                case 10: { 
                    roleIcon = "fa-user"
                   break; 
                } 
                case 20: { 
                    roleIcon = "fa-user-cog"
                   break; 
                } 
                case 30:  { 
                    roleIcon = "fa-user-shield"
                   break; 
                } 
                default: { 
                    roleIcon = "fa-user-lock"
                   break; 
                } 
             }
        }
        if (u && u.userTypeId > 0 ){
            switch(this.settingsService.user.userTypeId) { 
                case 900: { 
                    roleIcon = "fa-user-secret"
                   break; 
                } 
                case 1000: { 
                    roleIcon = "fa-user-ninja"
                   break; 
                } 
                default: { 
                    roleIcon = "fa-user-tag"
                   break; 
                } 
             }
        }
        return roleIcon;

    }

    get getUserRoleText():string {

        var roleText = null;

        switch(this.getUserRoleIcon) { 
            case "fa-user": { 
                roleText = "Read User"
               break; 
            } 
            case "fa-user-cog": { 
                roleText = "Device Manager"
               break; 
            } 
            case "fa-user-shield": { 
                roleText = "Account Admin"
               break; 
            } 
            case "fa-user-lock": { 
                roleText = "Unknown Role"
               break; 
            } 
            case "fa-user-alt-slash": { 
                roleText = "Undefined Role Object"
               break; 
            } 
            case "fa-user-secret": { 
                roleText = "Cloud Admin"
               break; 
            } 
            case "fa-user-ninja": { 
                roleText = "Cloud Super User"
               break; 
            } 
            case "fa-user-tag": { 
                roleText = "Uknown User Type"
               break; 
            } 
            default: { 
                roleText = "-------"
               break; 
            } 
         }

         return roleText
    }

    clickLogout(event) {
        console.log(event);
        this.authenticationService.logout();
    }

    clickProfile(event){
        console.log(event);
        let myUuid = this.settingsService.user.uuid.toString();
        let myRoute = "/users/" + myUuid + '/userProfile';

        this.router.navigate(['/users/userProfile/', this.settingsService.user.uuid.toString()], {replaceUrl: false}  )
    }


    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleCollapsedSideabar() {
        this.settingsService.layout.isCollapsed = !this.settingsService.layout.isCollapsed;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    isCollapsedText() {
        return this.settingsService.layout.isCollapsedText;
    }

    toggleFullScreen(event) {

        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        let el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removeClass('fa-expand').addClass('fa-compress');
        }
        else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }
}
