import { LayoutComponent } from '../layout/layout.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';
import { LoginComponent } from '../shared/component/login/login.component';
import { ResetPasswordComponent } from '../shared/component/resetpassword/resetpassword.component';
import { NewPasswordComponent } from '../shared/component/newpassword/newpassword.component';
import { RegisterComponent } from '../shared/component/register/register.component';
import { OverviewComponent } from './overview/overview.component';
import { MapModule } from './map/map.module';
import { MapComponent } from './map/map.component';
import { AlertsComponent } from './alerts/alerts.component';
import { DevicesViewComponent } from '../shared/component/views/devicesview/devicesview.component';
import { DevicesComponent } from './devices/devices.component';
import { ChartsComponent } from './charts/charts.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { DevicesAdminComponent } from './administration/devices/devices.component';
import { ClientsAdminComponent } from './administration/clients/clients.component';
import { UsersAdminComponent } from './administration/users/users.component';
import { FirmwaresAdminComponent } from './administration/firmwares/firmwares.component';
import { ModemsAdminComponent } from './administration/modems/modems.component';
import { ModelsAdminComponent } from './administration/models/models.component';
import { Component } from '@angular/core';
import { SubscriptionsAdminComponent } from './administration/subscriptions/subscriptions.component';
import { UploadComponent } from '../shared/component/upload/upload.component';
import { SearchComponent } from './administration/search/search.component';

export const routes = [

    { path: 'login', component: LoginComponent },
    { path: 'resetpassword', component: ResetPasswordComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'login/reset/:uuid', component: NewPasswordComponent },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthenticationGuard],
        children: [
            { path: '', redirectTo: 'overview', pathMatch: 'full' },
            { path: 'overview', data: { reuse: true }, component: OverviewComponent }, //loadChildren: () => import('./overview/overview.module').then(m => m.OverviewModule) 
            { path: 'charts', data: { reuse: true }, component: ChartsComponent }, //loadChildren: () => import('./charts/charts.module').then(m => m.ChartingModule) }
            { path: 'charts/:uuid', component: ChartsComponent },
            { path: 'map', data: { reuse: true }, component: MapComponent }, //loadChildren: () => import('./map/map.module').then(m => m.MapModule)
            { path: 'alerts', data: { reuse: true }, component: AlertsComponent }, // loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule)
            { path: 'devices', data: { reuse: true }, component: DevicesComponent }, // loadChildren: () () => import('./devices/devices.module').then(m => m.DevicesModule) 
            { path: 'devices/:uuid', component: DevicesComponent },
            { path: 'devices/upload', component: DevicesComponent },
            // { path: 'schedules', loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule) },
            { path: 'configurations', data: { reuse: true }, component: ConfigurationsComponent }, //loadChildren: () => import('./configurations/configurations.module').then(m => m.ConfigurationsModule) },
            { path: 'configurations/:uuid', component: ConfigurationsComponent },
            { path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule) },
            { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
            { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
            { path: 'administration/devices/all', data: { reuse: true }, component: DevicesAdminComponent },
            { path: 'administration/devices/:uuid', component: DevicesAdminComponent },
            { path: 'administration/clients/all', data: { reuse: true }, component: ClientsAdminComponent },
            { path: 'administration/clients/:uuid', component: ClientsAdminComponent },
            { path: 'administration/users/all', data: { reuse: true }, component: UsersAdminComponent },
            { path: 'administration/users/:uuid', component: UsersAdminComponent },
            { path: 'administration/firmwares/all', data: { reuse: true }, component: FirmwaresAdminComponent },
            { path: 'administration/firmwares/:uuid', component: FirmwaresAdminComponent },
            { path: 'administration/modems/all', data: { reuse: true }, component: ModemsAdminComponent },
            { path: 'administration/modems/:id', component: ModemsAdminComponent },
            { path: 'administration/models/all', data: { reuse: true }, component: ModelsAdminComponent },
            { path: 'administration/models/:id', component: ModelsAdminComponent },
            { path: 'administration/subscriptions/all', data: { reuse: true }, component: SubscriptionsAdminComponent },
            { path: 'administration/subscriptions/:uuid', component: SubscriptionsAdminComponent },
            { path: 'administration/search', data: { reuse: true }, component: SearchComponent },
            //{ path: 'administration', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule) }
        ]
    },
    // Not found
    { path: '**', redirectTo: 'overview' }
];
