<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand d-none d-sm-block d-md-block d-lg-block d-xl-block" href="#/">
            <div class="brand-logo">
                <img class="img-fluid" src="assets/img/logo-rs-190-1.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/logo-rs-single.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar();" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settingsService.layout.asideToggled =! settingsService.layout.asideToggled; $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <li class="nav-item d-none">
                <!-- d-md-block -->
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link" (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a>
        </li>
        <!-- END User avatar toggle-->
        <!-- START lock screen-->
        <li class="nav-item d-none ">
                <!-- d-md-block -->
            <a class="nav-link" title="Lock screen" [routerLink]="'/lock'">
                <em class="icon-lock"></em>
            </a>
        </li>
        <!-- END lock screen-->
    </ul>
    <!-- END Left navbar [formControl]="ngxControl" -->
    <!-- START Right Navbar-->
    <ul id="headerRightNavbar" class="navbar-nav flex-row">
        <!-- Search icon-->
        <li class="nav-item d-none">
            <a class="nav-link" (click)="openNavSearch($event)">
                <em class="icon-magnifier"></em>
            </a>
        </li>
        <li *ngIf="settingsService.clients && settingsService.clients.length == 1" class="nav-item nav-link pt-3 pr-3 pr-sm-3 pr-md-3">
            <div class="text-light">{{settingsService.clients[0].name}}</div>
        </li>
        <li *ngIf="settingsService.clients && settingsService.clients.length > 1" class="nav-item nav-link pr-3 pr-sm-3 pr-md-3">
                <ngx-select  
                [formControl]="clientDropdown"
                [allowClear]="false" 
                [items]="clients"
                optionValueField="uuid" 
                optionTextField="name" 
                autoSelectSingleOption="false"
                class="client-selector"
                (select)="doSelect($event)"
                (removed)="removed($event)" 
                (typed)="typed($event)" 
                placeholder="No Client Selected">
                </ngx-select>
        </li>
        <!-- Fullscreen (only desktops)-->
        <li class="nav-item d-none">
            <!-- d-md-block -->
            <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fas fa-expand"></em>
            </a>
        </li>
        <!-- START Alert menu-->
        <li class="nav-item dropdown dropdown-list mr-3" dropdown>
            <ng-template #userHover><div>{{settingsService.user?.first||''}}&nbsp;{{settingsService.user?.last||''}}<br/><i style="white-space: nowrap;font-size: smaller">{{getUserRoleText}}</i></div></ng-template>
            <a [tooltip]="userHover" placement="bottom" delay="1000" class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
                <em class="fas {{getUserRoleIcon}}" style="font-size:larger"></em>
            </a>
            <!-- START Dropdown menu-->
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated slideInDown"> 
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action" (click)="clickLogout($event)">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="fas fa-lock fa-1x "></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">Logout</p>
                                    <p class="m-0 text-muted text-sm">Exit & Clear Credentials</p>
                                </div>
                            </div>
                        </div>
                        <div class="list-group">
                            <!-- list item-->
                            <div class="list-group-item list-group-item-action" (click)="clickProfile($event)">
                                <div class="media">
                                    <div class="align-self-start mr-2">
                                        <em class="fas fa-user-circle fa-1x "></em>
                                    </div>
                                    <div class="media-body">
                                        <p class="m-0">User Profile</p>
                                        <p class="m-0 text-muted text-sm">Preferences & Notifications</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- list item-->
                        <!-- <div class="list-group-item list-group-item-action">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="fas fa-envelope fa-2x text-warning"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New e-mails</p>
                                    <p class="m-0 text-muted text-sm">You have 10 new emails</p>
                                </div>
                            </div>
                        </div> -->
                        <!-- list item-->
                        <!-- <div class="list-group-item list-group-item-action">
                            <div class="media">
                                <div class="align-self-start mr-2">
                                    <em class="fas fa-tasks fa-2x text-success"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">Pending Tasks</p>
                                    <p class="m-0 text-muted text-sm">11 pending task</p>
                                </div>
                            </div>
                        </div> -->
                        <!-- last list item-->
                        <!-- <div class="list-group-item list-group-item-action">
                            <span class="d-flex align-items-center">
                                <span class="text-sm">More notifications</span>
                                <span class="badge badge-danger ml-auto">14</span>
                            </span>
                        </div> -->
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>
        <!-- END Alert menu-->
    </ul>
    <!-- END Right Navbar-->

    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

</nav>
<!-- {{settingsService.user | json}} -->
<!-- END Top Navbar-->