
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from '../../../core/events/events.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../core/settings/settings.service';

import { Router, ActivatedRoute } from '@angular/router';
import { LibrariesService } from '../../../core/libraries/libraries.service';
import { ClientsAdminViewComponent } from '../../../shared/component/views/clients-admin-view/clients-admin-view.component';
import { ClientFormComponent } from '../../../shared/component/forms/clients/client-form/client-form.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-clients-admin',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsAdminComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ClientFormComponent) public clientForm: ClientFormComponent;
  @ViewChild(ClientsAdminViewComponent) public clientView: ClientsAdminViewComponent;
  //@ViewChild("btnDevWiFi") btnDevWiFi: ElementRef;

  userTypes = userTypes;
  userRoles = userRoles;

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public librariesService: LibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  private paramSub: any;
  public showForm: boolean = false;
  private firstLoad: boolean = true;


  public get dataLoading(): boolean {
    if (!this.clientView) return false;
    return this.clientView.dataLoading;
  }

  private _clientUuid: string = "";
  get clientUuid(): string {
    return this._clientUuid;
  }
  set clientUuid(newDeviceUuid: string) {
    if (newDeviceUuid && newDeviceUuid.toString().toLowerCase() == 'add' || this.librariesService.guidValidate(newDeviceUuid)) {
      this._clientUuid = newDeviceUuid;
      this.showForm = true;
    }
    else {
      this._clientUuid = "";
      this.showForm = false;

    }
  }

  ngOnInit() {
    this.paramSub = this.route.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.clientUuid = params['uuid'];

    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {

  }
  // userLoad(event) {
  //   this.showForm = true;
  // }


  onFormEvent(event) {

    switch (event.toString()) {
      case "saved":
        {
          //alert(this.clientUuid)
          this.settingsService.client = this.clientForm.newClient;
          break;
        }
      case "cancelled":
        {
          // //this.showForm = false;
          // this.clientUuid = "";
          // //this.location.go(this.router.createUrlTree(['/administration/users/all'], { relativeTo: this.route }).toString())
          // this.router.navigate(['/administration/users/all'])
          break;
        }
    }

  }

  onViewSelected(event) {

    if (event && event.uuid && event.uuid.toString().length > 10) {
      //var url = this.router.createUrlTree(['.',event.toString()], {relativeTo: this.route})
      //this.location.go(url.toString());
      this.router.navigate(['/administration/clients', event.uuid.toString()], { replaceUrl: false })
      //this.router.navigateByUrl('/administration/users/'+event.toString())
    }
  }

  buttonAdd(event) {
    //alert(this.clientForm.formState)
    //this.showForm = true;
    //this.clientForm.formState = this.clientForm.formStates.New
    this.router.navigate(['/administration/clients', 'add'], { replaceUrl: false })
  }

  buttonBack(event) {
    this.router.navigate(['/administration/clients/all'])
  }

  buttonEdit(event) {
    this.clientForm.formState = this.clientForm.formStates.Edit
  }

  buttonSave(event) {
    this.clientForm.formState = this.clientForm.formStates.Save
  }

  buttonCancel(event) {
    this.clientForm.formState = this.clientForm.formStates.Cancelled
  }

  buttonRefresh(event) {
    this.clientView.getClients();
  }

  clientCreatedEvent() {
    this.eventsService.triggerDataRefresh();
  }

  onDataLoading(event) {
    if (this.dataLoading && this.firstLoad) this.settingsService.showSpinner("clients", false, 250);
    this.firstLoad = false;

    if (!this.dataLoading) this.settingsService.hideSpinner("clients");
  }

}
