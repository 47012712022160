<ngx-spinner name="devices"></ngx-spinner>
<div class="row">
  <div class="col-12">
    <div class="card bg-default">
      <div class="card-header">

        <form [hidden]="deviceLocationUuid != ''" class="row" [formGroup]="fgCharts" autocomplete="off">
          <div class="col-lg-7 col-md-7 col-sm-6">
            <h4 class="pt-2">Select Device Location</h4>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-6 float-right text-right row">

            <table class="table m-0 p-0 border-0">
              <tbody>
                <tr>
                  <!-- <td class="m-0 p-0 border-0 text-nowrap">
                      <div class="btn btn-primary mr-3" (click)="buttonMapUS($event)">
                          <span class="fas fa-globe-americas"></span></div>
                    <div class="btn btn-primary mr-3" (click)="buttonMapFit($event)">
                      <span class="fas fa-expand"></span></div>
                  </td> -->
                  <td *ngIf="false" class="m-0 p-0 border-0">
                    <div class="input-group btn-group" btnRadioGroup
                      (click)="librariesService.validateFormGroup(fgCharts)" formControlName="deviceListingTypeId">
                      <label class="form-control btn btn-primary border-0" btnRadio="0" tabindex="0" role="button"><span
                          class="fas fa-map mr-2"></span>Map</label>
                      <label class="form-control btn btn-primary border-0" btnRadio="1" tabindex="0" role="button"><span
                          class="fas fa-th-list mr-2"></span>Listing</label>
                    </div>
                  </td>
                  <td class="m-0 p-0 border-0">
                    <button class="btn btn-primary ml-2" (click)="buttonRefresh()">
                      <img *ngIf="!dataLoading" src="assets/img/spinner-arrows-static.png" width="22" height="22">
                      <img *ngIf="dataLoading" src="assets/img/spinner-arrows.png" width="22" height="22">
                    </button>
                  </td>
                </tr>
              </tbody>


            </table>


          </div>
        </form>

        <form [hidden]="deviceLocationUuid == ''" class="row mb-1" [formGroup]="fgChartTypes" autocomplete="off">
          <div class="col-lg-5">
            <h5 class="pt-0 mb-0 pb-0 font-weight-bold location_name">{{deviceLocation ? deviceLocation.name : "Invalid
              Device
              Location"}}<span *ngIf="device">
                &nbsp;(<a [routerLink]="['/devices', device?.uuid]" title="{{device?.label}}">Device
                  {{device?.serial}}</a>)
              </span></h5>

            <label>{{getDeviceLocationAddressDisplay()}}</label>
          </div>
          <div class="col-lg-7 float-right">
            <div class="input-group btn-group" btnRadioGroup (click)="librariesService.validateFormGroup(fgChartTypes)"
              formControlName="chartTypeId">
              <label
                *ngIf="!device?.dataRequestRealTime && device?.lastActivity && settingsService.userAccessCheck(userTypes.EndUser,userRoles.Manager)"
                class="form-control btn btn-primary" (click)="requestRealTimeData()"
                title="Uploads Data on Next Connection"><span class="fas fa-download mr-2"></span>Request Data
                Now</label>
              <label *ngIf="device?.dataRequestRealTime && device?.nextActivity"
                class="text-secondary text-md font-weight-bold ml-2 mt-2 pr-2"><span
                  class="fas fa-alarm-clock mr-2"></span>Next Connection ≈ {{device?.nextActivity | date: 'mediumDate'}}
                {{device?.nextActivity | date: 'shortTime'}}</label>
              <label *ngIf="device?.dataRequestRealTime && !device?.nextActivity && device?.lastActivity"
                class="text-secondary text-md font-weight-bold ml-2 mt-2 pr-2"><span class="fas fa-alarm-clock mr-2"></span>
                Download Requested</label>

              <label class="form-control btn btn-primary border-0" btnRadio="0" tabindex="0" role="button"><span
                  class="fas fa-clock mr-2"></span>Time Period</label>
              <label class="form-control btn btn-primary border-0" btnRadio="1" tabindex="0" role="button"><span
                  class="fas fa-crosshairs mr-2"></span>Speed Bins</label>
              <label class="form-control btn btn-primary border-0" btnRadio="2" tabindex="0" role="button"><span
                  class="fas fa-table mr-2"></span>Data
                Table</label>
            </div>
          </div>
        </form>
      </div>


      <div *ngIf="deviceLocationUuid == '' && fgCharts.get('deviceListingTypeId').value == '1'" class="bg-body">
        <app-devicesview [chartView]=true (onDataLoading)="onDataLoading($event)"
          (onDeviceSelected)="onDeviceLocationSelect($event)"></app-devicesview>
      </div>



      <div *ngIf="deviceLocationUuid != '' && fgChartTypes.get('chartTypeId').value == '0'" class="m-0">
        <app-charts-timespan [deviceLocation]="deviceLocation"></app-charts-timespan>
      </div>
      <div *ngIf="deviceLocationUuid != '' && fgChartTypes.get('chartTypeId').value == '1'" class="m-0">
        <app-charts-speedbins [deviceLocation]="deviceLocation" [device]="device"></app-charts-speedbins>
      </div>
      <div *ngIf="deviceLocationUuid != '' && fgChartTypes.get('chartTypeId').value == '2'" class="m-0">
        <app-charts-datatables [deviceLocation]="deviceLocation"></app-charts-datatables>
      </div>

    </div>

  </div>
</div>