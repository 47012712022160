import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { ModelsService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-models-admin-view',
  templateUrl: './models-admin-view.component.html',
  styleUrls: ['./models-admin-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelsAdminViewComponent implements OnInit, AfterViewInit, OnDestroy {

  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  public modelsTemp: any[] = [];
  public models: any[] = [];
  public modelsSelected: any[] = [];

  private _modelId: number = -1;
  get modelId(): number {
    return this._modelId;
  }
  @Input() set modelId(Id: number) {
    this._modelId = Id
    if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
  }

  @Output() onViewSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @ViewChild('datatableFilter') datatableFilter: ElementRef;

  //ag-grid variables start

  public gridOptions: GridOptions;
  public chartThemes;
  public rowStyle;
  public isSelected: boolean = false;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }

  @Input() public chartView: boolean = false;

  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    private router: Router,
    public errorsService: ErrorsService,
    public modelsService: ModelsService) {
      this.chartThemes = [
        'ag-pastel',
        'ag-default',
        'ag-material-dark',
        'ag-vivid-dark',
        'ag-solar',
      ];
      this.rowStyle = {
        cursor: 'pointer'
      }
      this.gridOptions = <GridOptions>{
        rowData: this.gridData,
        columnDefs: this.columnDefs,
        rowStyle: this.rowStyle,
        rowHeight: 40,
        rowSelection: 'multiple',
        cursor: "pointer",
        suppressRowClickSelection: true,
        onCellClicked: (e) => {
          e.node.setSelected(true)
          this.isSelected = true;
      },
      //chartThemeOverrides: this.chartThemeOverrides,
      enableCharts: false,
      enableRangeSelection: false
      // pagination: true,
      // rowSelection: 'single'
    }
  }

  columnDefs = [
    { headerName: "Code", field: "code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Description", field: "description", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Target Firmware", field: "firmwareTarget.code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Identifier", field: "id", sortable: true, resizable: true, flex: 1, suppressMenu: true }
  ]

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    let self = this
    setTimeout(function () {
      self.getModels()
    }, 500);
  }

  getModels(): any {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);

    return this.modelsService.read()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          this.models = result;
          this.modelsTemp = result;
          if (this.datatableFilter) this.updateFilter(this.datatableFilter.nativeElement.value)
        },
        error => {
          this.notificationsService.error("Server Error (getModels)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
        });
  }

  updateFilter(event) {
    const val = event.toLowerCase();
    const temp = this.modelsTemp.filter(function (d) {
      return (
        (
          (d.code != null && d.code.toLowerCase().indexOf(val) !== -1) ||
          (d.description != null && d.description.toLowerCase().indexOf(val) !== -1) ||
          val.toString().trim() == ''
        )
      );
    });

    this.models = temp;
  }

  onSelect({ selected }) {
    
    this.modelsSelected.splice(0, this.modelsSelected.length);
    if (selected && selected[0].id) {
      this.onViewSelected.emit(selected[0]);
    }
  }

  onResize(event) {
    this.gridOptions.api.sizeColumnsToFit();
 
  }

  onRowSelected({selected}) {
    //console.log(event);
    this.modelsSelected.splice(0, this.modelsSelected.length);
    if (selected && selected[0].uuid) {
      console.log('onSelect Event', selected);
      this.onViewSelected.emit(selected[0]);
  }
}

  onRowClicked(event) {
    console.log(event)

    if (this.isSelected && !this.chartView) {
      this.router.navigate(['/administration/models/', event.node.data.id]);
    }

    else if(this.isSelected && this.chartView){
      console.log(event.node)
      this.router.navigate(['/charts/', event.node.data.deviceLocation.uuid]);
    }
 
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }

}
