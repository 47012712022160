
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output, AfterViewInit, Input, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuService } from '../../../../core/menu/menu.service';
import { NotificationsService } from 'angular2-notifications';
import { DeviceUpdateDto } from '../../../../models/deviceUpdate';
import { SettingsService, userTypes, userRoles } from '../../../../core/settings/settings.service';
import { AgGridModule } from 'ag-grid-angular';
import { GridOptions, ChartType } from 'ag-grid-community';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DevicesService, ClientsService, FirmwaresService } from '../../../../core/api/api.services';
import { EventsService } from '../../../../core/events/events.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ErrorsService } from '../../../../core/errors/errors.service';
import { Subject, Subscription } from 'rxjs';
import { element } from 'protractor';
import { LibrariesService } from '../../../../core/libraries/libraries.service';

@Component({
  selector: 'app-deviceregistrationsview',
  templateUrl: './deviceregistrationsview.component.html',
  styleUrls: ['./deviceregistrationsview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeviceRegistrationsViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onDeviceSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild(DatatableComponent) dataView: DatatableComponent;
  @Output() onDataLoading: EventEmitter<boolean> = new EventEmitter()
  @ViewChild('dataTableFilter') datatableFilter: ElementRef;


  //Observables
  private onDestroy$: Subject<void> = new Subject<void>();

  private dataRefreshSubscription: Subscription;

  public deviceUpdate = new DeviceUpdateDto();
  public bulkItemList: string[] = ["Client", "Firmware"]
  public bulkUpdating: boolean = false;
  public myDeviceTableSelections: any[] = [];
  public clients: any[] = [];
  public firmwares: any[] = [];
  public devicesTemp: any[] = [];
  public devices: any[] = [];
  public devicesSelected: any[] = [];
  public isSelected: boolean = false;
  public dataLoading: boolean = false;
  private firstLoad: boolean = true;
  public rowStyle;
  public sidebar;
  public rowSelection: 'single' | 'multiple' = 'multiple';


  datePipe = new DatePipe('en-US');

  //ag-grid variables start

  public gridOptions: GridOptions;
  public chartThemes;

  private _gridData: any = null;
  get gridData(): any {
    return this._gridData;
  }
  @Input() set gridData(newGridData: any) {
    this._gridData = newGridData;
  }



  constructor(
    public menu: MenuService,
    public eventsService: EventsService,
    public clientsService: ClientsService,
    public firmwaresService: FirmwaresService,
    public notificationsService: NotificationsService,
    public settingsService: SettingsService,
    public devicesService: DevicesService,
    private router: Router,
    private route: ActivatedRoute,
    public librariesService: LibrariesService,
    public errorsService: ErrorsService) {
      this.dataRefreshSubscription = this.eventsService.dataRefresh$.subscribe(() => {
        this.dataRefresh();
      });

    this.chartThemes = [
      'ag-pastel',
      'ag-default',
      'ag-material-dark',
      'ag-vivid-dark',
      'ag-solar',
    ];
    this.rowStyle = {
      cursor: 'pointer'
    }
    this.gridOptions = <GridOptions>{
      rowData: this.gridData,
      columnDefs: this.columnDefs,
      sideBar: this.sideBar,
      rowStyle: this.rowStyle,
      rowSelection: 'multiple',
      cursor: "pointer",
      suppressRowClickSelection: true,
      onCellClicked: (e) => {
        if (e.colDef.field != 'serial') { // cell is from non-select column
            e.node.setSelected(true);
            this.isSelected = true;
        }
    },


      //chartThemeOverrides: this.chartThemeOverrides,
      enableCharts: false,
      enableRangeSelection: true
      // pagination: true,
      // rowSelection: 'single'
    }
  }

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',

        toolPanelParams: {
          suppressRowGroups: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true

        }
      }
    ]
  }


  columnDefs = [
    {
      headerName: "Serial", field: "serial", sortable: true, suppressRowClickSelection: true, checkboxSelection: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        let myVar = data;
        return "<a href=" + 'administration/devices/' + data.data.uuid + ">" + myVar.value + "</a>"
      }
    },
    { headerName: "Model", field: "model.code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Current Firmware", field: "firmwareCurrent.code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Target Firmware", field: "firmwareTarget.code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Status", field: "deviceStatus.code", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Name/Label", field: "label", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Schedule", field: "deviceSchedule.name", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Location Name", field: "deviceLocation.name", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "Last Communication", field: "lastActivity", sortable: true, resizable: true, suppressMenu: true,
      cellRenderer: (data) => {
        return data.value ? (data.value != null
          ? (this.librariesService.datePipe.transform(new Date(data.value), 'MM-dd-yyyy HH:mm:ss'))
          : '') : '';
      }
    },
    { headerName: "Imei Number", field: "modemImei", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "SimICCID", field: "simIccid", sortable: true, resizable: true, suppressMenu: true },
    { headerName: "SimIMSI", field: "simImsi", sortable: true, resizable: true, width: 120, suppressMenu: true }
  ]



  ngOnInit() {
    console.log(this.route.routeConfig.component.name + ":ngOnInit", this)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toLowerCase().split("?")[0] == '/administration/devices/all') {
          console.log(this.route.routeConfig.component.name + ":NavigationEnd")

        }
      }
    });
  }

  ngOnDestroy(): void {
    this.dataRefreshSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    console.log(this.route.routeConfig.component.name + ":ngAfterViewInit", this)
    this.dataRefresh();
  }

  dataRefresh() {
    var self = this;
    if (!this.settingsService.client) {
      setTimeout(() => {
        self.dataRefresh();
      }, 100);
    }
    else {
      setTimeout(function () {
        self.getDevices()
      }, 500);
    }
  }

  refreshGrid() {
    this.autoSizeColumns();
  }


  autoSizeColumns() {
    // if (this.gridOptions) return

    let allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);

  }

  getDevices(): any {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 25);

    if (!this.settingsService.client) return;
    this.dataLoading = true;
    this.onDataLoading.emit(this.dataLoading)
    if (this.dataLoading) this.settingsService.showSpinner("devices", false, 250);

    return this.devicesService.read(true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (result: any) => {
          let resetOffset: boolean = this.devices.length == 0

          this.devices = result;
          this.devicesTemp = result;

          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
          if(!this.dataLoading) this.settingsService.hideSpinner("devices");
        },
        error => {
          this.notificationsService.error("Server Error (getDevices)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
          this.dataLoading = false;
          this.onDataLoading.emit(this.dataLoading)
        });
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat']

  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.devicesTemp.filter(function (d) {
      return (
        (d.deviceLocation != null && d.deviceLocation.name.toLowerCase().indexOf(val) !== -1) ||
        (d.deviceGroup != null && d.deviceGroup.label.toLowerCase().indexOf(val) !== -1) ||
        (d.serial || "").toLowerCase().indexOf(val) !== -1 ||
        (d.model != null && d.model.code.toLowerCase().indexOf(val) !== -1) ||
        (d.deviceSchedule != null && d.deviceSchedule.name.toLowerCase().indexOf(val) !== -1) ||
        (d.label || "").toLowerCase().indexOf(val) !== -1 ||
        !val


      );
    })
    // update the rows
    this.devices = temp;
  }

  onRowClicked(event) {
    console.log(event)

    if (!this.bulkUpdating && this.isSelected) {
      this.router.navigate(['/administration/devices/', event.node.data.uuid]);
    }
    else {
      console.log('bulk updating');
    }
  }

  onSelect({ selected }) {
    this.devicesSelected.splice(0, this.devicesSelected.length);
    if (selected && selected[0].uuid) {
      console.log(this.route.routeConfig.component.name + ":onSelect Event", selected);
      this.onDeviceSelected.emit(selected[0]);
      //this.router.navigate(['/administration/devices', selected[0].uuid])
      //alert(selected[0].uuid)
    }
  }

  getSelectedNodes() {
    this.myDeviceTableSelections = this.gridOptions.api.getSelectedNodes();
    /*for (var i = 0; i < this.myDeviceTableSelections.length; i++) {
      this.myDeviceTableSelections[i] = this.myDeviceTableSelections[i].data.uuid;
    }*/
    return this.myDeviceTableSelections;
  }

  onRowSelected(event) {
    //console.log(event);
  }

  downloadExcel() {
    let today = new Date();
    let dateString = this.datePipe.transform(new Date(today), "MM-dd-yy");
    let params = {
      columnKeys: ['serial', 'mode.code', 'firmwareCurrent.code', 'firmwareTarget.code', 'deviceStatus.code', 'label', 'deviceSchedule.name', 'deviceLocation.name', 'lastActivity', 'modemImei', 'simIccid', 'simImsi'],
      fileName: 'Device Listing ' + dateString,

      processCellCallback: this.myCellCallback,
    }
    this.gridOptions.api.exportDataAsExcel(params)
  }

  myCellCallback(params) {
    if (params.column.colId == 'lastActivity' ) {
      let formattedCell = new Date(params.value).toLocaleString();
      params = formattedCell;
      return params;
    }
    return params.value
  }
}
