<ngx-spinner name="deviceAdminForm"></ngx-spinner>
<div>
  <div class="form-group row">
    <div class="col-lg-12">
      <form [formGroup]="fgDevice">
        <div class="form-row">
          <div class="col-md-4 col-sm-12 mb-2">
            <label>Customer</label>
            <ng-select #clientUuid [selectOnTab]="true" [items]="clients" bindValue="uuid" bindLabel="name"
              [clearable]="false" id="clientUuid" name="clientUuid" formControlName="clientUuid" placeholder="Customer">
            </ng-select>
          </div>
          <div class="col-md-8 col-sm-12 mb-2">
            <label>Device Label</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.label ? device?.label : '&nbsp;'}}
            </div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('label'))" id="label" name="label"
              formControlName="label" type="text" placeholder="Device Reference Label" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('label'))" class="invalid-feedback">Please provide a valid
              Device Label
              (Minimum 10 Characters)</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Serial Number</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.serial ? device.serial : '&nbsp;'}}</div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('serial'))" id="serial" name="serial"
              formControlName="serial" type="text" placeholder="Device Serial Number" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('serial'))" class="invalid-feedback">Please provide a valid
              Serial Number</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Model</label>
            <ng-select #modelId [selectOnTab]="true" [items]="models" bindValue="id" bindLabel="code"
              [clearable]="false" id="modelId" name="modelId" formControlName="modelId" placeholder="Model">
            </ng-select>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Connectivity</label>
            <div *ngIf="!isEditMode" class="form-control">
              {{fgDevice.get('connectivityTypeId').value=='0' ? 'Wi-Fi' : 'Cellular'}}
            </div>
            <div *ngIf="isEditMode" class="input-group btn-group" (click)="librariesService.validateFormGroup(fgDevice)"
              btnRadioGroup formControlName="connectivityTypeId">
              <label class="form-control btn btn-primary" btnRadio="0" tabindex="0" role="button">Wi-Fi</label>
              <label class="form-control btn btn-primary" btnRadio="10" tabindex="1" role="button">Cellular</label>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-2">
            <label>Gps Mode</label>
            <div *ngIf="!isEditMode" class="form-control">
              {{fgDevice.get('gpsModeId').value=='0' ? 'Manual' : 'Auto'}}
            </div>
            <div *ngIf="isEditMode" class="input-group btn-group" (click)="librariesService.validateFormGroup(fgDevice)"
              btnRadioGroup formControlName="gpsModeId">
              <label class="form-control btn btn-primary" btnRadio="1" tabindex="0" role="button">Auto</label>
              <label class="form-control btn btn-primary" btnRadio="0" tabindex="1" role="button">Manual</label>
            </div>
          </div>
        </div>

        <div
          *ngIf="fgDevice.get('connectivityTypeId') && fgDevice.get('connectivityTypeId').value && fgDevice.get('connectivityTypeId').value == '10'"
          class="form-row">
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Modem</label>
            <ng-select #modemId [selectOnTab]="true" [items]="modems" bindValue="id" bindLabel="name" [clearable]="true"
              id="modemId" name="modemId" formControlName="modemId" placeholder="Modem">
            </ng-select>
            <div *ngIf="controlInvalidFlag(fgDevice.get('modemId'))" class="invalid-feedback">Modem Is Required</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>IMEI Number</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.modemImei ? device.modemImei : '&nbsp;'}}</div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('modemImei'))" id="modemImei"
              name="modemImei" formControlName="modemImei" type="text" placeholder="Modem IMEI Number" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('modemImei'))" class="invalid-feedback">Please provide a valid
              IMEI Number</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Sim ICCID</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.simIccid ? device.simIccid : '&nbsp;'}}
            </div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('simIccid'))" id="simIccid"
              name="simIccid" formControlName="simIccid" type="text" placeholder="SIM ICCID" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('simIccid'))" class="invalid-feedback">Please provide a valid simIccid number</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Sim IMSI</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.simImsi ? device.simImsi : '&nbsp;'}}
            </div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('simImsi'))" id="simImsi"
              name="simImsi" formControlName="simImsi" type="text" placeholder="SIM IMSI" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('simImsi'))" class="invalid-feedback">Please provide a valid simImsi number</div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label>IP Address</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.modemIpv4 ? device.modemIpv4 : '&nbsp;'}}
            </div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('modemIpv4'))" id="modemIpv4"
              name="modemIpv4" formControlName="modemIpv4" type="text" placeholder="Modem IP Address" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('modemIpv4'))" class="invalid-feedback">Please provide a valid
              IP Address</div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label>Phone Number</label>
            <div *ngIf="!isEditMode" class="form-control">{{device?.modemPhone ? device.modemPhone : '&nbsp;'}}
            </div>
            <input *ngIf="isEditMode" [class]="controlValidationClass(fgDevice.get('modemPhone'))" id="modemPhone"
              name="modemPhone" formControlName="modemPhone" type="text" placeholder="Phone Number" />
            <div *ngIf="controlInvalidFlag(fgDevice.get('modemPhone'))" class="invalid-feedback">Please provide a valid
              Phone Number</div>
          </div>
        </div>



        <div class="form-row">
          <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
            <label>Current Firmware</label>
            <div class="form-control">{{device?.firmwareCurrent?.code ? device?.firmwareCurrent?.code+' @
              '+ (device?.firmwareCurrent?.timeStamp | date:'short') : 'Unreported'}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
            <label>Target Firmware</label>
            <ng-select #firmwareUuidTarget [selectOnTab]="true" [items]="firmwares" bindValue="uuid" bindLabel="code"
              [clearable]="false" id="firmwareUuidTarget" name="firmwareUuidTarget" formControlName="firmwareUuidTarget"
              placeholder="Firmware">
            </ng-select>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 mb-2">
            <label>Current Configuration</label><i *ngIf='device?.deviceSchedule' class="far fa-link ml-2"
              title="Configuration" (click)="configLink()"></i>
            <div class="form-control">{{device?.deviceSchedule ? device.deviceSchedule.name : 'None'}}</div>
          </div>
        </div>
        <div class="d-flex mx-1 mt-4 mb-2 border-bottom">
          <h4 class="font-weight-bold mb-1">Subscription Information</h4>
        </div>
        <div class="form-row">
          <div class="col-lg-4 col-md-6 col-sm-12 mt-1 mb-2">
            <label>Sales Order No.</label><i class="far fa-link ml-2"
            title="Subscription" (click)="subscriptionLink()"></i>
            <div class="form-control">{{ saleOrderNum ? saleOrderNum : 'N/A' }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mt-1 mb-2">
            <label>Subscription Status</label>
            <div class="form-control">{{ subStatus ? subStatus : 'N/A' }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mt-1 mb-2">
            <label>Expiration Date</label>
            <div class="form-control">{{ expDate ? expDate : 'N/A'}}</div>
          </div>
        </div>
        <app-configOptionControl-component [deviceView]=true [device]=device [IsEditMode]="isEditMode">
        </app-configOptionControl-component>
        <!-- <div *ngIf="isEditMode" class="d-flex mx-1 mt-3 border-bottom">
          <h4 class="font-weight-bold mb-1">Available Configuration Options</h4>
        </div>
        <div *ngIf="isEditMode" class="form-row">
          <ng-template ngFor let-configOption [ngForOf]="configOptions">
            <app-config-option-card *ngIf="!isSelctedConfigOption(configOption.id)"
              (onConfigOptionAdded)="configOptionAdd($event)" [ConfigOption]="configOption" [IsEditMode]="isEditMode"
              [AllowConfigEdit]="false" class="col-lg-3 col-md-6 col-sm-12 mt-2"></app-config-option-card>
          <div *ngIf="!isSelctedConfigOption(configOption.code)" class="col-lg-3 col-md-6 col-sm-12 mt-2">
              <div class="card bg-light">
                <table class="table p-0">
                  <thead>
                    <tr>
                      <th scope="col" class="p-0" width="99%">
                        <h4 class="m-2">{{configOption.code}}</h4>
                      </th>
                      <th scope="col" class="align-middle p-0" width="1%">
                        <div class="btn fas fa-plus-square pl-1 pr-1 pt-1 pb-1 mr-1" width="22" height="22"></div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <p class="ml-2 mr-2">{{configOption.description}}</p>
              </div>
            </div>
          </ng-template>
        </div>

        <div class="d-flex mx-1 mt-4 border-bottom">
          <h4 class="font-weight-bold mb-1">{{ isEditMode ? "Selected " : "" }}Device Configuration Options</h4>
        </div>
        <div class="form-row mb-4">
          <ng-template ngFor let-configOptionSelected [ngForOf]="configOptionsSelected">
            <app-config-option-card (onConfigOptionDropDownSelected)="configOptionDropDownSelect($event)"
              (onConfigOptionRemoved)="configOptionRemove($event)" [ConfigOption]="configOptionSelected"
              [ValueText]="getConfigOptionValueText(configOptionSelected.id)" [IsEditMode]="isEditMode"
              [AllowConfigEdit]="true" class="col-lg-3 col-md-6 col-sm-12 mt-2"></app-config-option-card>
             <div class="col-lg-3 col-md-6 col-sm-12 }mt-2">
              <div class="card bg-light">
                <table class="table p-0">
                  <thead>
                    <tr>
                      <th scope="col" class="p-0" width="99%">
                        <h4 class="m-2">{{configOptionSelected.code}}</h4>
                      </th>
                      <th scope="col" class="align-middle p-0" width="1%">
                        <div *ngIf="isEditMode" class="btn fas fa-minus-square pl-1 pr-1 pt-1 pb-1 mr-1" width="22"
                          height="22"></div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <p class="ml-2 mr-2">{{configOptionSelected.description}}</p>
              </div>
            </div>
          </ng-template>
        </div>-->

        <div class="form-row">
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Last Login/Authentication</label>
            <div class="form-control">{{ device?.lastLogin ? (device?.lastLogin | date:'short') : '&nbsp;'}}</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-2">
            <label>Last Activity</label>
            <div class="form-control">{{ device?.lastLogin ? (device?.lastActivity | date:'short') : '&nbsp;'}}</div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-12">
      <div class="form-row">
        <div class="col-lg-12">
          <label>Device Location Settings</label>
          <app-devicelocationsettingsview></app-devicelocationsettingsview>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- {{device | json}}<br/><br/>
{{configOptionsSelected | json}}<br/><br/>
{{device.configOptionValues | json}} -->