
import { filter, takeUntil, windowWhen } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { environment } from '../../../environments/environment';
import { ClientsService, UsersService } from '../api/api.services';
import { EventsService } from '../events/events.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { ErrorsService } from '../errors/errors.service';
import { MappingService } from '../mapping/mapping.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

import { ReturnStatement } from '@angular/compiler';
import { JsonpClientBackend } from '@angular/common/http';
import { settings } from 'cluster';
import { valuesIn } from 'lodash';

declare var $: any;

export enum userTypes {
    EndUser = 0,
    Device = 800,
    CloudAdmin = 900,
    CloudSuper = 1000,
    CloudSuperSystem = 1001			
}
export enum userRoles {
    Unknown = 0,
    Reader = 10,
    Manager = 20,
    Administrator = 30
}

@Injectable({
    providedIn: 'root',
})
export class SettingsService implements OnInit, OnDestroy {

    //Observables
    private onDestroy$: Subject<void> = new Subject<void>();

    public app: any;
    public layout: any;
    private clientChecked: boolean = false;

    ngOnInit() {

    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    public _user: any = null;
    private userRequestInProcess: boolean = false;
    get user(): any {

        if (this.userRequestInProcess) {
            this._user = null;
            return this._user
        }

        //this is separated from above to improve performance
        let tknUserUuid = this.authenticationService.tokenUserUuid.toString().toLowerCase();
        if (tknUserUuid == '') {
            this._user = null;
            return this._user
        }

        //console.log('get user()',tknUserUuid)

        var self = this;
        if (!this._user || (tknUserUuid != this._user.uuid.toString().toLowerCase())) {

            //console.log(tknUserUuid, this._user == null ? "null" : this._user.uuid.toString().toLowerCase())
            this.userRequestInProcess = true;
            this.usersService.readByUuid(tknUserUuid)
                .toPromise()
                .then(
                    (result: any) => {
                        self._user = result;
                        self.userRequestInProcess = false;
                        //console.log('get user()', this.user);
                    })
                .catch(
                    error => {
                        this.notificationsService.error("Server Error (get user)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                        self.userRequestInProcess = false;
                    });
        }

        return this._user;

    }

    public _userClientRole: any = null;
    private userClientRequestInProcess: boolean = false;
    get userClientRole(): any {

        if (this.userClientRequestInProcess || this._user == null || this._client == null) {
            this._userClientRole = null;
            return this._userClientRole
        }

        var self = this;
        if (!this._userClientRole ||
            (
                this._user.uuid.toLowerCase() != this._userClientRole.userUuid.toLowerCase() ||
                this._client.uuid.toLowerCase() != this._userClientRole.clientUuid.toLowerCase()
            )
        ) {

            //console.log(this.authenticationService.tokenUserUuid, this._user == null ? "null" : this._user.uuid.toString().toLowerCase())
            this.userClientRequestInProcess = true;
            this.usersService.readUserClientRole(this.authenticationService.tokenUserUuid)
                .toPromise()
                .then(
                    (result: any) => {
                        self._userClientRole = result;
                        self.userClientRequestInProcess = false;
                        //console.log('get userClientRole()', this.userClientRole);
                    })
                .catch(
                    error => {
                        this.notificationsService.error("Server Error (get userClientRole)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                        self.userClientRequestInProcess = false;
                    });
        }

        return this._userClientRole;

    }

    userAccessCheck(userTypeMin: userTypes = userTypes.EndUser, userRoleMin: userRoles = userRoles.Reader): boolean {

        if (!this._user || !this._userClientRole) return false;

        if (this._user.userTypeId >= userTypeMin &&
            (
                this._user.userTypeId >= userTypes.CloudAdmin ||
                this._userClientRole.roleId >= userRoleMin
            )) return true

        return false;
    }

    userAdminCheck(userTypeMin: userTypes = userTypes.EndUser): boolean {

        if (!this._user) return false;

        if (this._user.userTypeId >= userTypeMin) return true

        return false;
    }

    private _client: any;
    get client(): any {

        //console.log('client:',this._client)

        var clientJson = sessionStorage.getItem('client');
        if (!clientJson) clientJson = localStorage.getItem('client');

        if (!this._client && this.clients && clientJson) {
            this._client = JSON.parse(clientJson);
        }

        if (!this._client && this.clients) {
            this._client = this.clients[0];
        }

        return this._client;
    }
    public clientSetAdmin: boolean = false;
    set client(newClient: any) {

        var uuid = null;
        if (this._client) uuid = this._client.uuid

        if (newClient) {
            this.clientChecked = false;
            this.updateClient(newClient).then(() => {
                if (this._client) {
                    sessionStorage.setItem('client', JSON.stringify(newClient));
                    localStorage.setItem('client', JSON.stringify(newClient));
                }
                else {
                    sessionStorage.removeItem('client');
                    localStorage.removeItem('client');
                }
                this.clientChecked = true
                this.finalizeClientChange(this.clientChecked, uuid);
            });
        }
        else if (this._client != null) {
            this.clientChecked = true;
            this._client = null;
            this.finalizeClientChange(this.clientChecked, uuid);
        }
    }

    async updateClient(newClient: any) {
        await this.getClients();
        this._client = this.clients.filter(x => x.uuid == newClient.uuid)[0];
    }

    finalizeClientChange(changed: boolean, uuid: string | null) {
        if (changed || (this._client != null && uuid != this._client.uuid)) {
            //this.mappingService.largeViewBoundsReset();
            console.log("customerChanged", this._client)
            //this.eventsService.changeMessage("customerChanged"); //JSON.stringify(this._client) 
            if (!this.clientSetAdmin) {
                window.location.href = "/" + this.router.url.toLowerCase().split("?")[0].split("/")[1];
            } 
        // this.clientSetAdmin = false;
        }
    }

    clearClients() {
        this.client = null;
        this._clients = null;
    }

    private _clients: any[] = null;
    private _clientsError: boolean = false;
    private _clientsInProgress: boolean = false;
    get clients(): any[] {
        if (this._clients) return this._clients
        if (this._clientsInProgress) return null;

        this._clientsInProgress = true;
        this._clients = null;
        this._clientsError = false;
        this.getClients();

        var self = this;

        (async () => {
            while (this._clientsInProgress) {
                await self.delay(1000);
            }

        })();

        return this._clients;
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private getClients(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.authenticationService.tokenUserUuid ||
                this.authenticationService.tokenUserUuid == "") {
                this._clientsInProgress = false;
                resolve(null);
                return
            }
    
            if (this.clients && this.clients.length > 0 && this.clientChecked) {
                this._clientsInProgress = false;
                resolve(this.clients);
                return;
            }
    
            // return this.clientsService.read()
            // .subscribe(
            //   (result: any) => {
            //     this.clients = result;
            // },
            // error => {
            //   this.notificationsService.error("Server Error (getClients)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true});
            // });
    
            let self = this;
    
            this.usersService.readClients(this.authenticationService.tokenUserUuid)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (result: any) => {
    
                        let chk = self.clients ? true : false
                        self._clients = result.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        
                        if (!chk &&
                            (self.clients && self.clients.length)
                        ) {
        
                            if (self.client) {
        
                                var sClients = this.clients.filter(function (c) {
                                    return c.uuid == self.client.uuid;
                                });
                                if (sClients && sClients.length == 0) {
                                    self.client = self.clients[0];
                                }
                            }
                        }
                        this._clientsInProgress = false;
                        resolve(this.clients);
                },
                        error => {
                            this._clients = [];
                            this._clientsInProgress = false;
                            this._clientsError = true;
                            this.notificationsService.error("Server Error (getUserClients)", this.errorsService.errorParse(error), { timeOut: 15000, clickToClose: true });
                            reject(error); 
                    });
                })

    }

    private _temperatureUnit: string = 'F'
    get TemperatureUnit(): string {
        return this._temperatureUnit;
    }
    set TemperatureUnit(newUnit: string) {
        if (newUnit.toUpperCase() == 'F' || newUnit.toUpperCase() == 'C')
            this._temperatureUnit = newUnit.toUpperCase();
    }


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private eventsService: EventsService,
        private notificationsService: NotificationsService,
        private authenticationService: AuthenticationService,
        public errorsService: ErrorsService,
        private clientsService: ClientsService,
        private usersService: UsersService,
        private mappingService: MappingService,
        private spinner: NgxSpinnerService,
    ) {

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'Radarsign Cloud',
            description: 'Device Management & Analytics Platform',
            year: ((new Date()).getFullYear())
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }


    private activeSpinner: boolean = false;
    showSpinner(
        name: string = undefined,
        background: boolean = true,
        delay: number = 1000) {
        //type: 'ball-spin-fade-rotating', 
        //type: 'ball-running-dots',

        var self = this;

        self.activeSpinner = true;
        console.log("showSpinner", name)
        setTimeout(() => {

            if (self.activeSpinner) {
                console.log("showSpinner-activeSpinner", name)

                if (background)
                    self.spinner.show(
                        name, {
                        type: 'ball-clip-rotate-multiple',
                        size: 'medium',
                        bdColor: 'rgba(0,0,0,0.10)',
                        color: 'rgba(0,140,50,0.5)',
                        fullScreen: true
                    }
                    )
                else
                    self.spinner.show(
                        name, {
                        type: 'ball-clip-rotate-multiple',
                        size: 'medium',
                        bdColor: 'transparent',
                        color: 'rgba(0,140,50,0.3)',
                        fullScreen: false
                    }
                    )
            }

        }, delay);


    }

    hideSpinner(name: string = undefined) {
        console.log("hideSpinner", name)
        var self = this;
        self.spinner.hide(name);
        self.activeSpinner = false;
    }

    getDeviceLocationsNewActiveDays() {
        return environment.deviceLocationsNewActiveDays;
    }
    getDeviceLocationsMostActiveDays() {
        return environment.deviceLocationsMostActiveDays;
    }
    getDeviceLocationsMostActiveMaxRecords() {
        return environment.deviceLocationsMostActiveMaxRecords;
    }


    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }



    JsonParseObject(jsonString: string): any {
        let jsonObject: any = null;
        try {
            jsonObject = JSON.parse(jsonString);


            if (jsonObject && typeof jsonObject == "object") {
                return jsonObject;
            }
        }
        catch (e) {
            //error
        }
        return null;
    }

    read(group: string, key: string, defaultValue: any) {
        let localObject: any = this.JsonParseObject(localStorage.getItem(group));
        let sessionObject: any = this.JsonParseObject(sessionStorage.getItem(group));
        if (sessionObject && sessionObject[key]) {
            return sessionObject[key]
        };
        if (localObject && localObject[key]) {
            return localObject[key];

        }
        return defaultValue;

    }

    write(group: string, key: string, value: any) {

        let localObject: any = this.JsonParseObject(localStorage.getItem(group));
        let sessionObject: any = this.JsonParseObject(sessionStorage.getItem(group));
        if (localObject) localObject[key] = value;
        localStorage.setItem(group, JSON.stringify(localObject))
        if (sessionObject) sessionObject[key] = value;
        sessionStorage.setItem(group, JSON.stringify(sessionObject))
        if (!sessionObject && !localObject) {
            let settingsObj: any = {};
            settingsObj[key] = value;
            settingsObj = JSON.stringify(settingsObj);
            sessionStorage.setItem(group, settingsObj);
            localStorage.setItem(group, settingsObj);

        }
        if (!sessionObject && localObject) {
            sessionObject = this.JsonParseObject(localStorage.getItem(group));
            sessionStorage.setItem(group, JSON.stringify(sessionObject));
        }


    }
}