<div class="row">
  <div *ngIf="(formState == formStates.Read || formState == formStates.New || formState == formStates.Edit)"
    class="col-md-6 align-middle mt-2">
    <h4 class="pt-2 mb-0">Device Location Settings: Active
      {{fgDeviceLocationSetting.get('recordDate').value | date:'MM-dd-yyyy'}}</h4>
  </div>
  <!-- Form Command Buttons -->
  <div class="col-md-6 float-right text-right">
    <button *ngIf="(formState == formStates.Read || formState == formStates.New || formState == formStates.Edit)"
      [disabled]="formState == formState.Saving" type="button" class="btn btn-primary mt-2"
      (click)="this.formState = this.formStates.Cancelled" title="Back/Previous">
      <span class="fas fa-chevron-left"></span></button>
    <button *ngIf="formEnabled && formState == formStates.Read" type="button" class="btn btn-primary mt-2 ml-2"
      title="Edit Selected Location" (click)="this.formState = this.formStates.Edit">
      <span class="fas fa-edit"></span>
    </button>
    <button *ngIf="formEnabled && formState == formStates.Read" type="button" class="btn btn-primary mt-2 ml-2"
      title="Delete Selected Location" (click)="openLocationDeleteModal(locationDeleteModal)">
      <span class="fas fa-trash"></span>
    </button>
    <button *ngIf="formEnabled && (formState == formStates.New || formState == formStates.Edit)"
      [disabled]="(!this.fgDeviceLocation.valid || !this.fgDeviceLocationSetting.valid)" title="Save Changes"
      type="button" class="btn btn-success mt-2 ml-2" (click)="this.formState = this.formStates.Save">
      <span class="fas fa-save"></span></button>
    <button *ngIf="formEnabled && (formState == formStates.New || formState == formStates.Edit)"
      [disabled]="formState != formStates.New && formState != formStates.Edit" title="Discard Changes" type="button"
      class="btn btn-cancel mt-2 ml-2" (click)="this.formState = this.formStates.Cancel">
      <span class="fas fa-ban"></span></button>
  </div>
</div>
<div *ngIf="formState == formStates.List" class="mt-2 pl-0">
  <div class="row">
    <div class="col-lg-6">
      <input type='text' #dataTableFilter class="form-control"
        placeholder='Type to filter by Name, Address, or Group...' (keyup)='updateFilter($event)' />
    </div>
    <div class="col-lg-6 text-right">
      <button *ngIf="formEnabled && formState == formStates.List" type="button" class="btn btn-primary"
        title="Add New Location" (click)="addNewLocation()">
        <span class="fas fa-plus"></span>
      </button>
    </div>
  </div>
  <ag-grid-angular #agGrid id="adminDevicesTable" name="adminDevicesTable" style="height: 500px; max-height: 500px;"
    class="ag-theme-balham adminDevicesTable mt-2 pt-2 border-0" [animateRows]="true" [suppressCellSelection]="true"
    [getDetailRowData]="true" [rowData]="deviceLocationSettings" (rowSelected)="onDeviceLocationSelect($event)"
    [chartThemes]="chartThemes" [rowStyle]="rowStyle" [gridOptions]="gridOptions"
    [getChartToolbarItems]="getChartToolbarItems">
  </ag-grid-angular>
</div>

<!--- <div *ngIf="formState == formStates.List" class="bg-body mt-2">
      <ngx-datatable #deviceLocationsTable class='bootstrap no-detail-row' (select)='onDeviceLocationSelect($event)'
        [selectionType]="'single'" [selected]="deviceLocationSettingsSelected" [columnMode]="'flex'" [footerHeight]="20"
        [rowHeight]="'auto'" [limit]="20" [rows]='deviceLocationSettings'>
        <ngx-datatable-column [resizeable]="false" name="Date" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row?.recordDate | date:'MM-dd-yyyy'}}</div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" name="Name" prop="deviceLocation.name" [flexGrow]="2">
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" name="Street Address" [flexGrow]="3">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row?.deviceLocation.address }}</div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" name="City, State/Prov, Postal" [flexGrow]="2"
          prop="deviceGroupUuid">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{getLocationAddressDisplay(row)}}</div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" name="Latitude" prop="deviceLocation.latitude" [flexGrow]="1">
        </ngx-datatable-column>
        <ngx-datatable-column [resizeable]="false" name="Longitude" prop="deviceLocation.longitude" [flexGrow]="1">
        </ngx-datatable-column>
      </ngx-datatable>
    </div>-->
<div [hidden]="!(formState == formStates.Read || formState == formStates.New || formState == formStates.Edit)"
  class="row mt-2">
  <div class="col-lg-6 col-md-12">
    <form class="form-group mb-0" [formGroup]="fgDeviceLocation" autocomplete="off">
      <div class="form-row">
        <div class="col-md-8 col-sm-7 col-12 mb-2">
          <label>Location Name</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('name').value ?
            fgDeviceLocation.get('name').value
            : '&nbsp;'}}
          </div>
          <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceLocation.get('name'))" name="inputLabel" id="inputLabel"
            formControlName="name" type="text" placeholder="Location Reference Name" autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('name'))" class="invalid-feedback">Please provide a
            Device
            Location name (Minimum 10 Characters)</div>
        </div>
        <div class="col-md-4 col-sm-5 col-12 mb-2">
          <label>Traffic Direction</label>
          <ng-select #bearingId [selectOnTab]="true" [items]="bearings" bindValue="id" bindLabel="description"
            [clearable]="false" id="bearingId" name="bearingId" formControlName="bearingId" placeholder="Direction">
          </ng-select>
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('bearingId'))" class="invalid-feedback">Traffic
            Direction Is
            Required</div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-2">
          <label>Address</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('address').value ?
            fgDeviceLocation.get('address').value
            : '--'}}
          </div>


          <div *ngIf="isEditMode" class="input-group ">
            <input [class]="controlValidationClass(fgDeviceLocation.get('address'))" id="address" name="address"
              formControlName="address" type="text" placeholder="Street Address" autocomplete="off" />
            <div class="input-group-append">
              <button onclick="this.blur();" (click)="doSearchAddressFreeform()" [disabled]="!fgDeviceLocation.get('postalCode').value ||
                     fgDeviceLocation.get('postalCode').value?.toString().trim() == ''|| 
                     !fgDeviceLocation.get('country').value ||
                     fgDeviceLocation.get('country').value?.toString().trim() == ''"
                *ngIf="formState == formStates.New || formState == formStates.Edit"
                title="Address Search For Coordinates" type="button"
                class="fas fa-search-location form-control btn btn-primary pr-2"></button>
            </div>
          </div>

          <div *ngIf="controlValidationClass(fgDeviceLocation.get('address'))" class="invalid-feedback">Primary
            Street
            address
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-5 mb-2">
          <label>City</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('city').value ?
            fgDeviceLocation.get('city').value
            : '--'}}
          </div>
          <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceLocation.get('city'))" id="city" name="city"
            formControlName="city" type="text" placeholder="City Name" autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('city'))" class="invalid-feedback">City Name</div>
        </div>
        <div class="col-md-3 mb-2">
          <label>State/Province Code</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('stateProvinceCode').value ?
            fgDeviceLocation.get('stateProvinceCode').value
            : '--'}}
          </div>
          <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceLocation.get('stateProvinceCode')) + 'uppercase'"
            id="stateProvinceCode" name="stateProvinceCode" formControlName="stateProvinceCode" type="text"
            placeholder="State/Province Code" autocomplete="off" maxlength="2" 
            (input)="fgDeviceLocation.get('stateProvinceCode').setValue($event.target.value.toUpperCase())" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('stateProvinceCode'))" class="invalid-feedback">
            State/Province
            Code
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <label >Postal Code</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('postalCode').value ?
            fgDeviceLocation.get('postalCode').value
            : '--'}}
          </div>
          <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceLocation.get('postalCode'))" id="postalCode"
            name="postalCode" formControlName="postalCode" type="text" placeholder="Postal Code" autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('postalCode'))" class="invalid-feedback">Postal Code
            Is
            Required
          </div>
        </div>
      </div>
      <div class="form-row">

        <div class="col-lg-6 col-md-4 col-sm-8 mb-2">
          <label>Country</label>
          <ng-select #country [selectOnTab]="true" [items]="countries" bindValue="codeAlpha3" bindLabel="name"
            [clearable]="false" id="country" name="country" formControlName="country" placeholder="Country Name">
          </ng-select>
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('country'))" class="invalid-feedback">Country Is
            Required</div>
        </div>

        <div class="col-lg-2 col-md-3 col-sm-4 mb-2">
          <label>Latitude</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('latitude').value ?
            fgDeviceLocation.get('latitude').value
            : '0'}}
          </div>
          <input *ngIf="isEditMode" [readonly]="true" [class]="controlValidationClass(fgDeviceLocation.get('latitude'))"
            id="latitude" name="latitude" formControlName="latitude" type="text" placeholder="Latitude"
            autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('latitude'))" class="invalid-feedback"></div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 mb-2">
          <label>Longitude</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocation.get('longitude').value ?
            fgDeviceLocation.get('longitude').value
            : '0'}}
          </div>
          <input *ngIf="isEditMode" [readonly]="true"
            [class]="controlValidationClass(fgDeviceLocation.get('longitude'))" id="longitude" name="longitude"
            formControlName="longitude" type="text" placeholder="Longitude" autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocation.get('longitude'))" class="invalid-feedback"></div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 mb-2 text-right">
          <div><label>&nbsp;</label></div>
          <button onclick="this.blur();" (click)="doCenterAddress()"
            [disabled]="fgDeviceLocation.get('latitude').value == 0 || fgDeviceLocation.get('longitude').value == 0"
            title="Locate Coordinates On Map" type="button"
            class="fas fa-map-marked-alt form-control btn btn-primary pr-2">
            <span class="pr-2"></span></button>
        </div>
      </div>
    </form>
    <form class="form-group" [formGroup]="fgDeviceLocationSetting" autocomplete="off">
      <div class="form-row">
        <div class="col-md-12 mt-2 mb-2">
          <div class="border-bottom border-primary font-weight-bold"><label class="text-primary">Speed Limit
              Reporting
              Settings (Does Not Affect Device Configuration)</label></div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3 mb-2">
          <label class="text-primary font-weight-bold" >Date</label>
          <div *ngIf="!isEditMode" class="form-control">{{ ( fgDeviceLocationSetting.get('recordDate').value ?
            fgDeviceLocationSetting.get('recordDate').value
            : currentDate ) | date:'MM-dd-yyyy' }}
          </div>

          <div *ngIf="isEditMode" class="input-group">
            <input bsDatepicker readonly [class]="controlValidationClass(fgDeviceLocationSetting.get('recordDate'))"
              style="background-color: white;" class="form-control" #dpRD="bsDatepicker" [maxDate]="currentDate" id="recordDate"
              name="recordDate" [(ngModel)]="fgDeviceLocationSetting.get('recordDate').value"
              formControlName="recordDate" [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY'}" />
            <!--- ^^ Neeed to change this to use Reactive Forms -->
            <div class="input-group-append">
              <span class="input-group-text input-group-text-override fas fa-calendar btn btn-primary"
                (click)="dpRD.toggle()" [attr.aria-expanded]="dpRD.isOpen"></span>
            </div>
            <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('recordDate'))" class="invalid-feedback">
              Required</div>
          </div>
        </div>

        <div class="col-md-2 mb-2">
          <label class="text-primary font-weight-bold" >Primary</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocationSetting.get('speedLimit').value ?
            fgDeviceLocationSetting.get('speedLimit').value
            : '0'}}
          </div>
          <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceLocationSetting.get('speedLimit'))"
            id="speedLimit" name="speedLimit" formControlName="speedLimit" type="text" placeholder="Speed"
            autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('speedLimit'))" class="invalid-feedback">
            {{fgDeviceLocationSetting.get('unitIdSpeed').value=='0'
            ? 'Required 5-70' : 'Required 10-120'}}</div>
        </div>

        <div class="col-md-2 mb-2" *ngIf="false">
          <label class="text-info font-weight-bold">Secondary</label>
          <div *ngIf="!isEditMode" class="form-control">{{fgDeviceLocationSetting.get('speedLimitSecondary').value ?
            fgDeviceLocationSetting.get('speedLimitSecondary').value : '0'}}
          </div>
          <input *ngIf="isEditMode" [class]="controlValidationClass(fgDeviceLocationSetting.get('speedLimitSecondary'))"
            id="speedLimitSecondary" name="speedLimitSecondary" formControlName="speedLimitSecondary" type="text"
            placeholder="Speed" autocomplete="off" />
          <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('speedLimitSecondary'))" class="invalid-feedback">
            0=Disable,
            {{fgDeviceLocationSetting.get('unitIdSpeed').value=='0' ? '5-60' : '10-80'}}</div>
        </div>

        <div class="col-md-5 mb-2">
          <!--- Speed UoM set based on Country Now -->
          <label class=" text-primary font-weight-bold">Unit of Measure</label>
          <div *ngIf="true || !isEditMode" class="form-control">
            {{fgDeviceLocationSetting.get('unitIdSpeed').value=='0' ? 'Miles per Hour' : 'Kilometers per Hour'}}
          </div>
          <div *ngIf="false && isEditMode" class="input-group btn-group"
            (click)="librariesService.validateFormGroup(fgDeviceLocationSetting)" btnRadioGroup
            formControlName="unitIdSpeed">
            <label class="form-control btn btn-primary" btnRadio="0" tabindex="0" role="button">MPH</label>
            <label class="form-control btn btn-primary" btnRadio="1" tabindex="0" role="button">KPH</label>
          </div>
        </div>

      </div>
      <div *ngIf="fgDeviceLocationSetting.get('speedLimitSecondary').value > 0">
        <div class="form-row">
          <div class="col-md-12 mt-2 mb-2">
            <div class="border-bottom border-info font-weight-bold"><label class="text-info">Secondary Speed Limit
                Schedule</label></div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3 mb-2">
            <label class="text-info font-weight-bold">Time 1 Start</label>
            <div *ngIf="!isEditMode" class="form-control">
              {{fgDeviceLocationSetting.get('speedLimitSecondaryTime1Begin').value
              ? fgDeviceLocationSetting.get('speedLimitSecondaryTime1Begin').value : '&nbsp;'}}
            </div>
            <ng-select [selectOnTab]="true" [items]="timeSpans" bindValue="value" bindLabel="display"
              [clearable]="false" id="speedLimitSecondaryTime1Begin" name="speedLimitSecondaryTime1Begin"
              formControlName="speedLimitSecondaryTime1Begin" placeholder="None">
            </ng-select>
            <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('speedLimitSecondaryTime1Begin'))"
              class="invalid-feedback">Time
              1 Start</div>
          </div>
          <div class="col-md-3 mb-2">
            <label class="text-info font-weight-bold">Time 1 End</label>
            <div *ngIf="!isEditMode" class="form-control">
              {{fgDeviceLocationSetting.get('speedLimitSecondaryTime1End').value
              ? fgDeviceLocationSetting.get('speedLimitSecondaryTime1End').value : '&nbsp;'}}
            </div>
            <ng-select [selectOnTab]="true" [items]="timeSpans" bindValue="value" bindLabel="display"
              [clearable]="false" id="speedLimitSecondaryTime1End" name="speedLimitSecondaryTime1End"
              formControlName="speedLimitSecondaryTime1End" placeholder="None">
            </ng-select>
            <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('speedLimitSecondaryTime1End'))"
              class="invalid-feedback">Time
              1 Start</div>
          </div>
          <div class="col-md-3 mb-2">
            <label class="text-info font-weight-bold">Time 2 Start</label>
            <div *ngIf="!isEditMode" class="form-control">
              {{fgDeviceLocationSetting.get('speedLimitSecondaryTime2Begin').value
              ? fgDeviceLocationSetting.get('speedLimitSecondaryTime2Begin').value : '&nbsp;'}}
            </div>
            <ng-select [selectOnTab]="true" [items]="timeSpans" bindValue="value" bindLabel="display"
              [clearable]="false" id="speedLimitSecondaryTime2Begin" name="speedLimitSecondaryTime2Begin"
              formControlName="speedLimitSecondaryTime2Begin" placeholder="None">
            </ng-select>
            <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('speedLimitSecondaryTime2Begin'))"
              class="invalid-feedback">Time
              1 Start</div>
          </div>
          <div class="col-md-3 mb-2">
            <label class="text-info font-weight-bold">Time 2 End</label>
            <div *ngIf="!isEditMode" class="form-control">
              {{fgDeviceLocationSetting.get('speedLimitSecondaryTime2End').value
              ? fgDeviceLocationSetting.get('speedLimitSecondaryTime2End').value : '&nbsp;'}}
            </div>
            <ng-select [selectOnTab]="true" [items]="timeSpans" bindValue="value" bindLabel="display"
              [clearable]="false" id="speedLimitSecondaryTime2End" name="speedLimitSecondaryTime2End"
              formControlName="speedLimitSecondaryTime2End" placeholder="None">
            </ng-select>
            <div *ngIf="controlInvalidFlag(fgDeviceLocationSetting.get('speedLimitSecondaryTime2End'))"
              class="invalid-feedback">Time
              1 Start</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-lg-6 col-md-12">
    <label>Location Map<span *ngIf="isEditMode">:&nbsp;&nbsp;<svg width="14" height="11"
          xmlns="http://www.w3.org/2000/svg">
          <circle stroke="white" stroke-width="0.5" fill="#4DB546" cx="6" cy="6" r="5" />
        </svg>Active&nbsp;&nbsp;</span><span *ngIf="isEditMode">&nbsp;&nbsp;<svg width="14" height="11"
          xmlns="http://www.w3.org/2000/svg">
          <circle stroke="white" stroke-width="0.5" fill="#7B9EB0" cx="6" cy="6" r="5" />
        </svg>Current Or
        Previous&nbsp;&nbsp;</span><span *ngIf="isEditMode && searchAddressResult.length > 0">&nbsp;&nbsp;<svg
          width="14" height="11" xmlns="http://www.w3.org/2000/svg">
          <circle stroke="white" stroke-width="0.5" fill="#4B96F3" cx="6" cy="6" r="5" />
        </svg>Search Result</span></label>
    <mgl-map #mglMap id="mapContainer" [style.visibility]="isMapLoaded ? 'visible' : 'hidden'" class="map"
      [style]="'mapbox://styles/mapbox/streets-v11'" [zoom]="[mapZoomDefault]" [center]="[mapLngDefault, mapLatDefault]"
      (load)="onMapLoad($event)">
      <mgl-control mglNavigation position="top-right"></mgl-control>

      <ng-container *ngFor="let loc of deviceLocationsAll">
        <mgl-marker *ngIf="isEditMode" [draggable]="false" [anchor]="center" [offset]="[-5,-12]"
          [lngLat]="[loc.longitude, loc.latitude]">
          <div (click)="markerPrevLocClick(loc, $event)"
            [innerHtml]="mappingService.getMarkerTypeSvg(markerTypes.PreviousLocation) | safeHtml"></div>
        </mgl-marker>
      </ng-container>
      <ng-container *ngFor="let addr of searchAddressResult">
        <mgl-marker *ngIf="isEditMode" [draggable]="false" [anchor]="center" [offset]="[-5,-12]"
          [lngLat]="[addr.position.lng, addr.position.lat]">
          <div (click)="markerSearchAddrClick(addr, $event)"
            [innerHtml]="mappingService.getMarkerTypeSvg(markerTypes.SearchLocation) | safeHtml"></div>
        </mgl-marker>
      </ng-container>
      <mgl-marker #markerLoc
        *ngIf="fgDeviceLocation.get('longitude').value != 0 && fgDeviceLocation.get('latitude').value != 0"
        [draggable]="isEditMode" [anchor]="center" [offset]="[-5,-12]"
        [lngLat]="[fgDeviceLocation.get('longitude').value, fgDeviceLocation.get('latitude').value]"
        (dragEnd)="markerLocDragEnd($event)">
        <div id="markerLoc" (click)="markerLocClick($event)" [innerHtml]="getMarkerSvg() | safeHtml"></div>
      </mgl-marker>
    </mgl-map>
  </div>
</div>
<ng-template #locationDeleteModal>
  <div class="modal-header">
    <h3 class="m-0">Confirm Delete</h3>
  </div>
  <div class="modal-body text-center">
    <i class="fas fa-trash-alt fa-2x bg-primary rounded p-3"></i>
    <h4 class="pt-3">This action deletes the Location Setting and<br />will not affect collected Data.</h4>
    <h4 class="pt-2">Delete the selected Location Setting?</h4>
    <h6 class="pt-3 pb-3">(Deletion is immediate)</h6>
    <button type="button" class="btn btn-delete mr-3" (click)="LocationDeleteConfirm()">Delete</button>
    <button type="button" class="btn btn-cancel" (click)="LocationDeleteCancel()">Cancel</button>
  </div>
</ng-template>